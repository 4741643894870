import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../components/dynamicTable/table";
import Flippy from "../../components/flippy/flippy";
import ImageUpload from "../../components/imageUpload/imageUpload";
import Loader from "../../components/loader/loader";
import { AddSvg } from "../../components/svg/add";
import { EditSvg } from "../../components/svg/edit";
import { Facebook } from "../../components/svg/facebook";
import { Identity } from "../../components/svg/identity";
import { Instagram } from "../../components/svg/instagram";
import { LocationSvg } from "../../components/svg/location";
import { Mail } from "../../components/svg/mail";
import { Phone } from "../../components/svg/phone";
import { TikTok } from "../../components/svg/tikTok";
import { Twitter } from "../../components/svg/twitter";
import { useGetArtistTypesQuery } from "../../features/artistType/artistTypeApiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useGetInstrumentsQuery } from "../../features/instruments/instrumentsApiSlice";
import { useGetArtistInvestorsQuery } from "../../features/investors/investorsApiSlice";
import { useGetArtistQuery } from "../../features/users/usersApiSlice";
import {
  ArtistType,
  BenefitsEnum,
  InstrumentType,
} from "../../interface/account";
import { Investor } from "../../interface/investor";
import { adminRoute, artistRoute } from "../../routes";

const ArtistProfile = () => {
  const navigate = useNavigate();
  const [investors, setInvestors] = useState<any[]>([]);
  const { userId: artistId, isLoading: isLoadingArtist } =
    useSelector(selectCurrentUser);
  const { data: instrumentsList } = useGetInstrumentsQuery({});
  const { data: artistTypeList } = useGetArtistTypesQuery({});
  const { roles } = useSelector(selectCurrentUser);
  const location = useLocation();

  const { data: userDetails, isLoading } = useGetArtistQuery(artistId, {
    skip: !artistId,
  });

  const benefitsMedia = {
    [BenefitsEnum.FUNERAL_COVER]: "/funeral.png",
    [BenefitsEnum.SAVINGS_FUND]: "/savings.png",
    [BenefitsEnum.LEGAL_ADVICE]: "/legal.png",
    [BenefitsEnum.MEDICAL_AID]: "/medical.png",
    [BenefitsEnum.PENSION_FUND]: "/pension.png",
    [BenefitsEnum.LIFE_COVER]: "/life.png",
  };

  const ArtistType = () => {
    if (userDetails && userDetails.artistTypes) {
      const otherId = artistTypeList?.find(
        (x: any) => x.description === "Other"
      )?.artistTypeId;

      return (
        <>
          <p>Type:</p>
          <div style={{ display: "flex", gap: "0px 15px", flexWrap: "wrap" }}>
            {userDetails.artistTypes.map((artistType: ArtistType) => {
              return (
                <p
                  key={`${artistType.artistTypeId}_${artistType.description}`}
                  className="pill mt-0"
                >
                  {artistType.artistTypeId === otherId
                    ? artistType.description
                    : artistTypeList?.find(
                        (x: any) => x.artistTypeId === artistType.artistTypeId
                      )?.description}
                </p>
              );
            })}
          </div>
        </>
      );
    }
    return <></>;
  };

  const InstrumentTypes = () => {
    if (
      userDetails &&
      userDetails.instrumentTypes &&
      userDetails.instrumentTypes.length > 0
    ) {
      const otherId = instrumentsList?.find(
        (x: any) => x.description === "Other"
      )?.instrumentTypeId;

      return (
        <>
          <p>Instruments:</p>
          <div style={{ display: "flex", gap: "0px 15px", flexWrap: "wrap" }}>
            {userDetails.instrumentTypes.map(
              (instrumentType: InstrumentType) => {
                return (
                  <p
                    key={`${instrumentType.instrumentTypeId}_${instrumentType.description}`}
                    className="pill mt-0"
                  >
                    {instrumentType.instrumentTypeId === otherId
                      ? instrumentType.description
                      : instrumentsList?.find(
                          (x: any) =>
                            x.instrumentTypeId ===
                            instrumentType.instrumentTypeId
                        )?.description}
                  </p>
                );
              }
            )}
          </div>
        </>
      );
    }
    return <></>;
  };

  const isApproval = useMemo(
    () =>
      (roles.includes("Admin") &&
        !userDetails?.rejectReason &&
        !userDetails?.approved) ||
      location.pathname.includes("approve"),
    [roles, userDetails]
  );

  const { data: investorsData, isLoading: isLoadingInvestors } =
    useGetArtistInvestorsQuery(artistId);

  useEffect(() => {
    if (investorsData?.collection) {
      const mappedInvestors = investorsData?.collection?.map((c: Investor) => {
        return {
          ...c,
          name: `${c.name} ${c.surname}`,
          mandateStatus: c.status,
          comments: c.comments.some((x) => !x.resovled) ? (
            <svg
              width="25px"
              height="25px"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.5 12C5.49988 14.613 6.95512 17.0085 9.2741 18.2127C11.5931 19.4169 14.3897 19.2292 16.527 17.726L19.5 18V12C19.5 8.13401 16.366 5 12.5 5C8.63401 5 5.5 8.13401 5.5 12Z"
                  stroke="#a8b147"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M9.5 13.25C9.08579 13.25 8.75 13.5858 8.75 14C8.75 14.4142 9.08579 14.75 9.5 14.75V13.25ZM13.5 14.75C13.9142 14.75 14.25 14.4142 14.25 14C14.25 13.5858 13.9142 13.25 13.5 13.25V14.75ZM9.5 10.25C9.08579 10.25 8.75 10.5858 8.75 11C8.75 11.4142 9.08579 11.75 9.5 11.75V10.25ZM15.5 11.75C15.9142 11.75 16.25 11.4142 16.25 11C16.25 10.5858 15.9142 10.25 15.5 10.25V11.75ZM9.5 14.75H13.5V13.25H9.5V14.75ZM9.5 11.75H15.5V10.25H9.5V11.75Z"
                  fill="#a8b147"
                ></path>
              </g>
            </svg>
          ) : (
            ""
          ),
        };
      });

      setInvestors(mappedInvestors);
    }
    return () => {};
  }, [investorsData]);

  const investorHeaders = [
    {
      header: "Full Name",
      identifier: "name",
      order: 0,
    },
    {
      header: "Email",
      identifier: "email",
      order: 1,
    },
    {
      header: "Mobile",
      identifier: "mobile",
      order: 2,
    },
    {
      header: "Status",
      identifier: "mandateStatus",
      order: 3,
    },
    {
      header: "",
      identifier: "comments",
      order: 4,
    },
  ];

  return (
    <>
      {isLoading || isLoadingArtist || !userDetails.systemUserId ? (
        <Loader />
      ) : (
        <>
          <div className="profile-grid">
            <div className="profile">
              <div className="card p-0 flex flex-column">
                <ImageUpload
                  entityId={artistId}
                  img={userDetails.profileImageUrl}
                  fullWidth
                  float
                />
                <div className="pl-1 pb-1 pr-1">
                  <div
                    className={`status-label status-${
                      !!userDetails?.rejectReason
                        ? "rejected"
                        : userDetails?.suspended
                        ? "rejected"
                        : userDetails?.approved
                        ? "active"
                        : "inactive"
                    }`}
                  >
                    <p>
                      {!!userDetails?.rejectReason
                        ? "Rejected"
                        : userDetails?.suspended
                        ? "Suspended"
                        : userDetails?.approved
                        ? "Active"
                        : "Inactive"}
                    </p>
                    <span className="tooltiptext">
                      {userDetails?.suspended
                        ? userDetails.suspendReason
                        : userDetails?.rejectReason}
                    </span>
                  </div>
                  <h2 className="mt-0">
                    {userDetails?.name} {userDetails?.surname}
                  </h2>
                  <div className="flex flex-row align-items-center p-0">
                    <LocationSvg />
                    <p className="text-capitalize m-0">
                      {userDetails?.address3}
                      {userDetails?.address4 ? ", " : null}
                      {userDetails?.address4}
                    </p>
                  </div>
                  {ArtistType()}
                  {InstrumentTypes()}
                </div>
              </div>
            </div>
            <div className="profile-details">
              <div className="card">
                <div className="flex flex-row justify-content-space-between align-items-center p-0">
                  <h2>About</h2>
                  {!isApproval && (
                    <a
                      className="edit-btn"
                      href={
                        roles.includes("Admin")
                          ? adminRoute.EditArtist(artistId)
                          : artistRoute.EditArtist(artistId)
                      }
                    >
                      <EditSvg />
                    </a>
                  )}
                </div>
                <h3 className="color-primary mt-1">CONTACT INFORMATION</h3>
                <div className="input-grid m-0">
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <div className="icon-gb">
                      <Identity />
                    </div>
                    <p>ID number:</p>
                  </div>
                  <p className="color-gray">
                    {userDetails?.idNumber ? userDetails.idNumber : "N/A"}
                  </p>
                </div>
                <div className="input-grid m-0">
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <div className="icon-gb">
                      <Mail />
                    </div>
                    <p>Email address:</p>
                  </div>
                  <p className="color-gray">
                    {userDetails?.email ? userDetails.email : "N/A"}
                  </p>
                </div>
                <div className="input-grid m-0">
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <div className="icon-gb">
                      <Phone />
                    </div>
                    <p>Contact number:</p>
                  </div>
                  <p className="color-gray">
                    {userDetails?.mobile ? userDetails.mobile : "N/A"}
                  </p>
                </div>
                <h3 className="color-primary mt-1">SOCIAL MEDIA PLATFORMS</h3>
                <div className="input-grid m-0">
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <>
                      <div className="icon-gb">
                        <Facebook />
                      </div>
                      <p>Facebook:</p>
                    </>
                    {userDetails?.facebookHandle ? (
                      <a
                        className="color-gray handle-text"
                        target="_blank"
                        href={`https://facebook.com/${userDetails.facebookHandle}`}
                        rel="noreferrer"
                      >
                        {userDetails?.facebookHandle}
                      </a>
                    ) : (
                      <p className="color-gray handle-text">N/A</p>
                    )}
                  </div>
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <>
                      <div className="icon-gb">
                        <Instagram />
                      </div>
                      <p>Instagram:</p>
                    </>
                    {userDetails?.instagramHandle ? (
                      <a
                        className="color-gray handle-text"
                        target="_blank"
                        href={`https://instagram.com/${userDetails.instagramHandle}`}
                        rel="noreferrer"
                      >
                        {userDetails?.instagramHandle}
                      </a>
                    ) : (
                      <p className="color-gray handle-text">N/A</p>
                    )}
                  </div>
                </div>
                <div className="input-grid m-0">
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <>
                      <div className="icon-gb">
                        <Twitter />
                      </div>
                      <p>Twitter:</p>
                    </>
                    {userDetails?.twitterHandle ? (
                      <a
                        className="color-gray handle-text"
                        target="_blank"
                        href={`https://twitter.com/${userDetails.twitterHandle}`}
                        rel="noreferrer"
                      >
                        {userDetails?.twitterHandle}
                      </a>
                    ) : (
                      <p className="color-gray handle-text">N/A</p>
                    )}
                  </div>
                  <div className="flex flex-row justify-content-start p-0 align-items-center">
                    <>
                      <div className="icon-gb">
                        <TikTok />
                      </div>
                      <p>TikTok:</p>
                    </>
                    {userDetails?.tikTokHandle ? (
                      <a
                        className="color-gray handle-text"
                        target="_blank"
                        href={`https://tiktok.com/@${userDetails.tikTokHandle}`}
                        rel="noreferrer"
                      >
                        {userDetails?.tikTokHandle}
                      </a>
                    ) : (
                      <p className="color-gray handle-text">N/A</p>
                    )}
                  </div>
                </div>
              </div>
              {!isApproval && (
                <>
                  {userDetails?.benefits?.length > 0 && (
                    <div className="benefits-container">
                      <h2>
                        {roles.includes("Admin")
                          ? `${userDetails?.name}'s`
                          : "My"}{" "}
                        Benefits
                      </h2>
                      <div className="benefits-content flex flex-wrap p-0">
                        {userDetails?.benefits.map((c: any) => (
                          <Flippy
                            key={c.benefit}
                            title={c.benefit}
                            img={benefitsMedia[c.benefit as BenefitsEnum]}
                            value={c.amount.toLocaleString("ru-RU")}
                          >
                            <p className="flippy-title mt-1">{c.benefit}</p>
                            <div className="detail-wrapper">
                              <p className="label">Provider</p>
                              <p className="label-value">
                                {!!c.companyName
                                  ? c.companyName
                                  : "Not provided"}
                              </p>

                              {!!c.companyContact && (
                                <a
                                  className="label-value"
                                  href={`tel:${c.companyContact}`}
                                >
                                  <img
                                    src="/phone.svg"
                                    alt="phone"
                                    height={16}
                                    width={17}
                                  />
                                  {c.companyContact}
                                </a>
                              )}
                              <p className="label mt-0">Policy no.</p>
                              <p className="label-value">
                                {!!c.policyNumber
                                  ? c.policyNumber
                                  : "Not provided"}
                              </p>
                            </div>
                          </Flippy>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="card">
                    <div className="flex-row justify-content-space-between align-items-center p-0">
                      <h3>Angel Investors</h3>
                      <a
                        className="flex-row align-items-center strong"
                        href={
                          roles.includes("Admin")
                            ? adminRoute.CreateArtistDonor(artistId)
                            : artistRoute.CreateArtistDonor(artistId)
                        }
                      >
                        <AddSvg />
                        Add investor
                      </a>
                    </div>
                    {!isLoadingInvestors ? (
                      <Table
                        headers={investorHeaders}
                        dataset={investors}
                        redirectFn={(data) => {
                          navigate(
                            roles.includes("Admin")
                              ? adminRoute.EditArtistDonor(
                                  artistId,
                                  data.investorId
                                )
                              : artistRoute.EditArtistDonor(
                                  artistId,
                                  data.investorId
                                )
                          );
                        }}
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ArtistProfile;
