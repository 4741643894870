import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextInput from "../../components/textInput/textInput";
import { Investor } from "../../interface/investor";
import { Validators } from "../../validators";
import { useCreateDonorMutation } from "../../features/investors/investorsApiSlice";
import Loader from "../../components/loader/loader";
import ContactMethods from "../../components/contactMethods/contactMethods";
import { NotificationMethod } from "../../interface/account";
const CreateDonor = () => {
  const navigate = useNavigate();
  const [investor, setInvestor] = useState<Investor>({
    name: "",
    surname: "",
    email: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    occupation: "",
    systemUserId: "",
    notificationMethod: NotificationMethod[NotificationMethod.Email],
  } as unknown as Investor);

  const [addInvestor, { isLoading: submitting }] = useCreateDonorMutation();

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    idNumber: [
      {
        check: Validators.isID,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    occupation: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvestor({ ...investor, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(investor, validations);
    setValidations({ ...validations });

    if (valid) {
      addInvestor(investor)
        .unwrap()
        .then(() => {
          window.location.replace("http://www.artscapital.co.za/thank-you");
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }

      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleContactChange = (key: string, value: string) => {
    setInvestor((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="flex-row justify-content-space-between p-0">
        <h1>Become a donor</h1>
      </div>
      <ContactMethods
        value={investor.notificationMethod}
        onChange={(value) => handleContactChange("notificationMethod", value)}
      />
      <div>
        <p className="title">Personal Information</p>
        <div className="input-grid">
          <TextInput
            name="name"
            type="text"
            value={investor.name}
            placeholder="Name"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="surname"
            type="text"
            value={investor.surname}
            placeholder="Surname"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="idNumber"
            type="number"
            value={investor.idNumber}
            placeholder="ID number"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="string"
            name="mobile"
            placeholder="Contact number"
            value={investor.mobile}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="email"
            name="email"
            placeholder="Email address"
            value={investor.email}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="occupation"
            type="text"
            value={investor.occupation}
            placeholder="Occupation"
            inputChanged={handleChange}
            validations={validations}
          />
        </div>
        <p className="title">Address Information</p>
        <div className="input-grid">
          <TextInput
            type="text"
            name="address1"
            placeholder="Street address"
            value={investor.address1}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address2"
            placeholder="Unit / Building no."
            value={investor.address2}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address3"
            placeholder="Suburb"
            value={investor.address3}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address4"
            placeholder="Province"
            value={investor.address4}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="number"
            name="address5"
            placeholder="Postal Code"
            value={investor.address5}
            inputChanged={handleChange}
            validations={validations}
          />
        </div>

        <button type="submit" onClick={onSubmit}>
          {!submitting ? "Register" : <Loader />}
        </button>
      </div>
    </>
  );
};

export default CreateDonor;
