import { apiSlice } from "../../app/api/apiSlice";
import { AccountDetailRequestInterface } from "../../interface/account";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials: any) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    register: builder.mutation<any, AccountDetailRequestInterface>({
      query: (credentials: any) => ({
        url: "/auth/register",
        method: "POST",
        body: credentials,
      }),
    }),
    registerAdmin: builder.mutation<any, AccountDetailRequestInterface>({
      query: (credentials: any) => ({
        url: "/auth/register-admin",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    forgotPassword: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useRegisterAdminMutation,
  useForgotPasswordMutation,
} = authApiSlice;
