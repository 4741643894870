export const AddSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 36 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="17.5016"
        cy="20.3695"
        rx="17.5016"
        ry="17.352"
        fill="#A8B147"
      />
      <path
        d="M23.9292 18.0332V21.6153H10.3465V18.0332H23.9292ZM19.0018 11.4525V28.6336H15.3549V11.4525H19.0018Z"
        fill="white"
      />
    </svg>
  );
};
