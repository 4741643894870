import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  useGetAdminQuery,
  useUpdateAdminMutation,
} from "../../features/users/usersApiSlice";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/textInput/textInput";
import { adminRoute } from "../../routes";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { FormEvent, useState } from "react";
import {
  AccountDetailRequestInterface,
  NotificationMethod,
} from "../../interface/account";
import { Validators } from "../../validators";
import { useRegisterAdminMutation } from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import ContactMethods from "../../components/contactMethods/contactMethods";
import Loader from "../../components/loader/loader";
const CreateAdministrator = () => {
  const navigate = useNavigate();
  const { roles } = useSelector(selectCurrentUser);
  const [registerAdmin, { isLoading: submitting }] = useRegisterAdminMutation();
  const [admin, setAdmin] = useState<AccountDetailRequestInterface>({
    name: "",
    surname: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    idNumber: "",
    profileImageUrl: null,
    email: "",
    mobile: "",
    artistTypes: null,
    instrumentTypes: null,
    facebookHandle: "",
    twitterHandle: "",
    instagramHandle: "",
    tikTokHandle: "",
    benefits: [],
    pensionFund: 0,
    medicalAid: 0,
    savings: 0,
    lifeCover: 0,
    funeralCover: 0,
    legalCover: 0,
    approved: false,
    suspended: false,
    suspendReason: "",
    notificationMethod: NotificationMethod[NotificationMethod.Email],
  });

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    idNumber: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isID,
        isValid: true,
      },
    ],
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const handleContactChange = (key: string, value: string) => {
    setAdmin((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAdmin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(admin, validations);
    setValidations({ ...validations });

    if (valid) {
      registerAdmin(admin)
        .unwrap()
        .then((payload) => {
          navigate(adminRoute.Administrators());
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }
      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  return (
    <>
      <h1 className="mb-2">Create Administrator</h1>
      <ContactMethods
        value={admin.notificationMethod}
        onChange={(value) => handleContactChange("notificationMethod", value)}
      />
      <div>
        <p className="title">Personal Information</p>
        <div className="input-grid">
          <TextInput
            type="text"
            name="name"
            placeholder="Name"
            value={admin.name}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="surname"
            placeholder="Surname"
            value={admin.surname}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="string"
            name="mobile"
            placeholder="Contact number"
            value={admin.mobile}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="email"
            name="email"
            placeholder="Email address"
            value={admin.email}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="number"
            name="idNumber"
            placeholder="ID number"
            value={admin.idNumber}
            inputChanged={handleChange}
            validations={validations}
          />
        </div>
        <p className="title">Address Information</p>
        <div className="input-grid">
          <TextInput
            type="text"
            name="address1"
            placeholder="Street address"
            value={admin.address1}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address2"
            placeholder="Unit / Building no."
            value={admin.address2}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address3"
            placeholder="Suburb"
            value={admin.address3}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address4"
            placeholder="Province"
            value={admin.address4}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="number"
            name="address5"
            placeholder="Postal Code"
            value={admin.address5}
            inputChanged={handleChange}
            validations={validations}
          />
        </div>
        {roles?.includes("SuperAdmin") ? (
          <label
            style={{ width: "fit-content" }}
            className="flex flex-row align-items-center p-0"
          >
            <input
              type="checkbox"
              className="brand-check"
              checked={roles.includes("SuperAdmin") ? true : false}
              onChange={() => {}}
            />
            Super user
          </label>
        ) : null}
        <div className="flex flex-row pl-0 mt-2">
          <button
            className="gray"
            onClick={(e) => {
              e.preventDefault();
              navigate(adminRoute.Administrators());
            }}
          >
            Back
          </button>
          <button type="submit" onClick={handleSubmit}>
            {!submitting ? "Create" : <Loader />}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateAdministrator;
