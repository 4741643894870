import { apiSlice } from "../../app/api/apiSlice";
import { ArtistType } from "../../interface/account";

export const artistTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    getArtistTypes: builder.query<ArtistType[], any>({
      query: () => "/artists/types",
      keepUnusedDataFor: 60,
    }),
  }),
});

export const {
  // GETS
  useGetArtistTypesQuery,
} = artistTypeApiSlice;
