export const Phone = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84388 8.33209L7.55646 8.77533C6.01139 7.99982 5.05697 7.109 4.50153 5.7204L4.92919 3.42636L4.12078 1.27686H2.03736C1.41107 1.27686 0.917888 1.79441 1.01142 2.41367C1.24494 3.95964 1.93345 6.76268 3.94608 8.77533C6.05965 10.8889 9.10376 11.806 10.7791 12.1706C11.4261 12.3114 12 11.8067 12 11.1446V9.1537L9.84388 8.33209Z"
        fill="#4c4c4c"
        stroke="#4c4c4c"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
