import { Outlet } from "react-router";
import Navbar from "../components/navbar/navbar";

const SharedLayout = () => {
  return (
    <>
      <Navbar hideMenu={true} />
      <div
        className="container"
        style={{ marginTop: "150px", paddingBottom: "5rem" }}
      >
        <Outlet />
      </div>
    </>
  );
};
export default SharedLayout;
