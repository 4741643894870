import { apiSlice } from "../../app/api/apiSlice";
import { Investor } from "../../interface/investor";
import { PageSearchQuery, PageSearchResponse } from "../../interface/queries";
import { Supplier } from "../../interface/supplier";

export const suppliersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    getSuppliers: builder.query<PageSearchResponse, PageSearchQuery>({
      query: (query) => ({
        url: `suppliers`,
        method: "GET",
        params: query,
      }),
      keepUnusedDataFor: 60,
      providesTags: ["Artists", "users"],
    }),
    getSupplier: builder.query<Supplier, any>({
      query: (supplierId) => "/suppliers/" + supplierId,
      keepUnusedDataFor: 60,
      providesTags: ["Artists", "users"],
    }),
    getArtistSuppliers: builder.query<any, any>({
      query: (supplierId) => "/artist-supplier/supplier/" + supplierId,
      keepUnusedDataFor: 60,
      providesTags: ["Artists", "users"],
    }),
    // POSTS
    createSupplier: builder.mutation<any, Supplier>({
      query: (payload: any) => ({
        url: "/suppliers",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Artists", "users"],
    }),
    createArtistSupplier: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "/artist-supplier",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Artists", "users"],
    }),
    // PUTS
    updateSupplier: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "/suppliers/" + payload.supplierId,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Artists", "users"],
    }),
    updateArtistSupplier: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "/artist-supplier/" + payload.artistSupplierId,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Artists", "users"],
    }),
  }),
});

export const {
  // GETS
  useGetSupplierQuery,
  useGetSuppliersQuery,
  useGetArtistSuppliersQuery,
  // POSTS
  useCreateSupplierMutation,
  useCreateArtistSupplierMutation,
  //Puts
  useUpdateSupplierMutation,
  useUpdateArtistSupplierMutation,
} = suppliersApiSlice;
