import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  accessToken: string;
  refreshToken: string;
  isLoggedIn: boolean;
  email: string;
  userId: string;
  roles: role[];
  givenName: string;
}

export type role = "Admin" | "Artist" | "SuperAdmin";

interface login {
  email: string;
  password: string;
}
interface loginResponse {
  token: string;
}
const authDefaultState: AuthState = {
  isLoggedIn: false,
  accessToken: "",
  refreshToken: "",
  email: "",
  userId: "",
  roles: [],
  givenName: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: authDefaultState,
  reducers: {
    setCredentials: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.userId = action.payload.userId;
      state.roles = action.payload.roles;
      state.email = action.payload.email;
      state.givenName = action.payload.givenName;
    },
    logOut: (state) => {
      state.accessToken = "";
      state.isLoggedIn = false;
      state.userId = "";
      state.roles = [];
      state.email = "";
      state.givenName = "";
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth;
export const selectCurrentToken = (state: any) => state.auth.accessToken;
