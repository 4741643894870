export interface Investor {
  systemUserInvestorId?: number;
  systemUserId: number;
  id?: number;
  name: string;
  surname: string;
  email: string;
  mobile: number;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  occupation?: string;
  status: InvestorStatusEnum;
  reason: string;
  idNumber?: number;
  comments: Comment[];
  isActive?: boolean;
  debitOrderDay: string;
  notificationMethod: string;
}

export interface Comment {
  comment: string;
  createdBy: string;
  createdByEmail: string;
  createdOn: Date;
  investorCommentId: number;
  resolvedBy: string;
  resolvedOn: Date;
  resovled: boolean;
}

export interface InvestorStatus {
  systemUserId: number;
  systemUserInvestorId: number;
  status: string;
  reason: string;
}

export enum InvestorStatusEnum {
  Rejected = "rejected",
  Pending = "pending",
  Unavailable = "unavailable",
  OptedOut = "optedOut",
}

export interface PageSearchResponse {
  collection: Investor[];
  totalNumberOfRecords: Number;
  totalNumberOfPages: Number;
  pages: [];
}

export interface PageSearchQuery {
  term: string;
  pageNumber: number;
  pageSize: number;
}
