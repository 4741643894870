import { apiSlice } from "../../app/api/apiSlice";

export const instrumentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    getInstruments: builder.query({
      query: () => "/instruments",
      keepUnusedDataFor: 6000,
    }),
  }),
});

export const {
  // GETS
  useGetInstrumentsQuery,
} = instrumentsApiSlice;
