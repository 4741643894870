export const Mail = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.44687V1.44687C0 0.673676 0.626801 0.046875 1.4 0.046875H12.6C13.3732 0.046875 14 0.673676 14 1.44687V8.44687C14 9.22009 13.3732 9.84688 12.6 9.84688H1.4C0.626801 9.84688 0 9.22009 0 8.44687Z"
        fill="#4c4c4c"
      />
      <path d="M3.5 2.58789L7 5.03789L10.5 2.58789" fill="#4c4c4c" />
      <path
        d="M3.5 2.58789L7 5.03789L10.5 2.58789"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
