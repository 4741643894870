import { ChangeEvent } from "react";
import { NotificationMethod } from "../../interface/account";

interface ContactMethodProps {
  onChange: (data: string) => void;
  value: string;
}

const ContactMethods = ({ onChange, value }: ContactMethodProps) => {
  const email = NotificationMethod[NotificationMethod.Email];
  const sms = NotificationMethod[NotificationMethod.SMS];
  const both = NotificationMethod[NotificationMethod.BOTH];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div>
      <p className="title">Preferred Contact Method</p>
      <div className="flex flex-row align-items-center p-0">
        <label className="flex flex-row align-items-center p-0 g-0">
          <input
            type="radio"
            className="brand-radio"
            value={email}
            checked={value === email}
            onChange={handleChange}
          />
          Email
        </label>
        <label className="flex flex-row align-items-center p-0 g-0">
          <input
            type="radio"
            value={sms}
            className="brand-radio"
            checked={value === sms}
            onChange={handleChange}
          />
          SMS
        </label>
        <label className="flex flex-row align-items-center p-0 g-0">
          <input
            type="radio"
            className="brand-radio"
            value={both}
            checked={value === both}
            onChange={handleChange}
          />
          Both
        </label>
      </div>
    </div>
  );
};

export default ContactMethods;
