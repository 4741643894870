import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { setCredentials, selectCurrentUser } from "../features/auth/authSlice";
import {
  useForgotPasswordMutation,
  useLoginMutation,
} from "../features/auth/authApiSlice";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Validators } from "../validators";
import TextInput from "../components/textInput/textInput";
import { adminRoute, artistRoute, sharedRoute } from "../routes";
import jwtDecode from "jwt-decode";
import Loader from "../components/loader/loader";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser.isLoggedIn) {
      navigateToRole();
    }
    return () => {};
  }, [currentUser]);

  const handleForgot = async (e: any) => {
    e.preventDefault();
    if (!!email && Validators.isEmail(email)) {
      forgotPassword(email)
        .unwrap()
        .then(() => {
          setSubmitted(true);
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      toast.error("Please provide us with a valid email address.", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setEmail(value);
  };

  const navigateToRole = () => {
    if (currentUser.roles.includes("SuperAdmin")) {
      if (location.state) {
        navigate(
          `${location.state.previousPage}${location.state.searchParams}`
        );
        return;
      }
      navigate(adminRoute.Administrators());
      return;
    }

    if (currentUser.roles.includes("Admin")) {
      if (location.state) {
        navigate(
          `${location.state.previousPage}${location.state.searchParams}`
        );
        return;
      }
      navigate(adminRoute.Artists());
      return;
    }

    if (currentUser.roles.includes("Artist")) {
      if (location.state) {
        navigate(
          `${location.state.previousPage}${location.state.searchParams}`
        );
        return;
      }
      navigate(artistRoute.profile(currentUser.userId));
      return;
    }
  };
  if (!submitted)
    return (
      <div className="p-0 login fullscreen flex align-items-center justify-content-center">
        <div className="card curved login-card shadowed">
          <div className="login-form flex align-items-center justify-content-center">
            <div className="flex-column width-100 align-items-center">
              <img
                src="./Arts-Capital-logo.png"
                width={200}
                alt="login"
                className="mb-2"
              />
              <TextInput
                type="email"
                name="email"
                placeholder="Email address"
                value={email}
                inputChanged={handleChange}
              />
              <button className="width-100" onClick={handleForgot}>
                {!isLoading ? "Change password" : <Loader />}
              </button>
              <a href={sharedRoute.Login()} className="text-align-center">
                Back to login
              </a>
            </div>
          </div>
          <div className="img-overlay">
            <img
              src="https://nuresvmgrpdiag.blob.core.windows.net/artscapital-website/login.png"
              className="login-bg-image"
              alt="login"
            />
          </div>
        </div>
      </div>
    );

  return (
    <div className="p-0 fullscreen flex align-items-center justify-content-center">
      <div className="card curved login-card shadowed">
        <div className="text-align-center">
          <div className="p-2">
            <img
              src="./Arts-Capital-logo.png"
              width={200}
              alt="login"
              className="mb-2"
            />
            <p>
              We've received your password reset request.
              <br />
              Check your email for instructions on resetting your password.
            </p>
            <p>
              <strong>Note:</strong> If you don't see the email in your inbox,
              please check your spam folder.
            </p>
            <a href="/">Back to home</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
