import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { selectCurrentUser } from "../features/auth/authSlice";
import { role } from "../interface/account";
import { sharedRoute } from "../routes";

const ProtectedRoute = ({
  role,
  children,
}: {
  role: role[];
  children: JSX.Element;
}) => {
  const { id } = useParams<{ id: any }>();
  const {
    isLoggedIn,
    roles: roleState,
    userId,
  } = useSelector(selectCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();

  const render = useMemo(() => {
    // TODO: change to /login after deployment
    if (!isLoggedIn)
      return (
        <Navigate
          to={sharedRoute.Login()}
          replace
          state={{
            previousPage: location.pathname,
            searchParams: location.search,
          }}
        />
      );

    if (location.pathname.includes("admin") && !roleState.includes("Admin")) {
      navigate("/not-allowed");
      return <Navigate to="/not-allowed" replace />;
    }
    if (
      !location.pathname.includes("admin") &&
      location.pathname.includes("artist") &&
      !roleState.includes("Artist")
    ) {
      navigate("/not-allowed");
      return <Navigate to="/not-allowed" replace />;
    }

    if (
      roleState.includes("Artist") &&
      location.pathname.includes("/artist/accounts/") &&
      id !== userId
    )
      return <Navigate to="/not-allowed" replace />;

    return children;
  }, [children, isLoggedIn, role, roleState, userId]);

  return render;
};

export default ProtectedRoute;
