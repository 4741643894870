/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../app/reduxHooks";
import { useOtpVerifyMutation } from "../../features/otp/otpApiSlice";
import {
  countdown,
  otpState,
  resetOtpLink,
  setTokens,
  verifyOtpTimer,
} from "../../features/otp/otpSlice";
import { OtpInterface } from "../../interface/login";
import "./otp.scss";
import OtpInput from "./otpInput";

const formatCountdown = (countdown: number): string => {
  const seconds = Math.floor((countdown / 1000) % 60);
  const minutes = Math.floor((countdown / 1000 / 60) % 60);
  const hours = Math.floor((countdown / 1000 / 60 / 60) % 24);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const RE_DIGIT = new RegExp(/[0-9]{6}$/);

const Otp = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { timer, resetLink } = useSelector(otpState);
  const [otp, setOtp] = React.useState<OtpInterface>({
    otp: "",
    key: params.get("key"),
    token: params.get("token"),
  } as OtpInterface);

  const [addOtp] = useOtpVerifyMutation();

  useEffect(() => {
    if (otp.key && otp.token) {
      dispatch(setTokens({ key: otp.key, token: otp.token }));
      dispatch(verifyOtpTimer({ key: otp.key, token: otp.token }));
    }

    return () => {};
  }, [otp.key, otp.token]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addOtp(otp)
      .unwrap()
      .then(() =>
        navigate({
          pathname: "/setup-password",
          search: `?${createSearchParams({
            key: otp.key,
            token: otp.token,
            otp: otp.otp,
          })}`,
        })
      )
      .catch((error) => {
        toast.error(error.data.message, {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!resetLink) {
        dispatch(countdown()); // decrement countdown by 1 second (1000 milliseconds)
      }
    }, 1000);

    return () => {
      clearInterval(interval); // clear the interval on component unmount
    };
  });

  const disabledValue = useMemo(() => {
    if (otp.otp.length < 6) return true;
    if (!RE_DIGIT.test(otp.otp)) return true;
    return false;
  }, [otp.otp]);

  return (
    <div className="otp-container">
      <div className="otp-logo">
        <img src="./Arts-Capital-logo.png" alt="logo"></img>
      </div>
      <div className="form-wrapper">
        {resetLink ? (
          <div>
            <h5>Reset OTP</h5>
            <p>
              We have received your request for a new OTP (One-Time Password).
              <br /> we've sent a new OTP to your registered email.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} autoComplete="off">
            <h5>Verify your account</h5>
            <h2>OTP Verification</h2>
            {timer > 0 ? (
              <>
                <p>
                  Hey there! To keep your account secure, we just need you to
                  enter the OTP (One-Time Password)
                  <br /> we've sent to your registered email. It's an extra step
                  to make sure it's really you.
                  <br />
                  <br /> This code will expire in:{" "}
                  {timer && <span>{formatCountdown(timer)}</span>}
                </p>
                <OtpInput
                  value={otp.otp}
                  valueLength={6}
                  onChange={(value: string) => {
                    setOtp({ ...otp, otp: value });
                  }}
                />
                <button type="submit" disabled={disabledValue}>
                  Continue
                </button>
              </>
            ) : (
              <>
                <p>
                  Oh dear! It seems your OTP has expired. <br />
                  Please click the link below to request a fresh one.
                </p>
              </>
            )}
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                dispatch(
                  resetOtpLink({ key: otp.key, token: otp.token })
                ).unwrap();
              }}
            >
              Resend OTP Code
            </a>
          </form>
        )}
      </div>
    </div>
  );
};

export default Otp;
