import axios from "axios";
import moment from "moment";
import React, { FormEvent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextInput from "../../components/textInput/textInput";
import { selectCurrentUser } from "../../features/auth/authSlice";
import {
  useCommentMutation,
  useCreateInvestorMutation,
  useResolveCommentMutation,
} from "../../features/investors/investorsApiSlice";
import { Comment, Investor } from "../../interface/investor";
import { adminRoute, artistRoute } from "../../routes";
import { Validators } from "../../validators";
import Loader from "../../components/loader/loader";
import { error } from "console";
import ContactMethods from "../../components/contactMethods/contactMethods";
import { NotificationMethod } from "../../interface/account";
const CreateArtistDonor = () => {
  const { investorId, artistId } = useParams();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [investor, setInvestor] = useState<Investor>({
    name: "",
    surname: "",
    email: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    occupation: "",
    systemUserId: artistId?.toString() ?? "",
    notificationMethod: NotificationMethod[NotificationMethod.Email],
  } as unknown as Investor);
  const {
    roles,
    givenName: userGivenName,
    email: userEmail,
  } = useSelector(selectCurrentUser);

  const [createInvestor, { isLoading: submitting }] =
    useCreateInvestorMutation();
  const [createComment, { isLoading: submittingComment }] =
    useCommentMutation();
  const [resolveComment] = useResolveCommentMutation();

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    idNumber: [],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    occupation: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const AdminValidators = {
    ...validations,
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  };

  useMemo(() => {
    if (roles.includes("SuperAdmin") || roles.includes("Admin"))
      setValidations(AdminValidators);
  }, [roles]);

  useMemo(() => {
    if (
      !!investor.idNumber &&
      investor.idNumber > 0 &&
      validations["idNumber"]?.length === 0
    )
      setValidations({
        ...validations,
        idNumber: [
          //@ts-ignore
          {
            check: Validators.isID,
            isValid: true,
          },
        ],
      });
    if (!!!investor.idNumber)
      setValidations({
        ...validations,
        idNumber: [],
      });
  }, [investor]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInvestor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(investor, validations);
    setValidations({ ...validations });

    if (valid) {
      createInvestor(investor)
        .unwrap()
        .then(() => {
          !!artistId && roles.includes("Artist")
            ? navigate(artistRoute.profile(artistId))
            : !!artistId && !roles.includes("Artist")
            ? navigate(adminRoute.Artist(artistId))
            : window.location.replace("http://www.artscapital.co.za/thank-you");
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }

      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleCommentAdd = () => {
    createComment({
      artistId,
      investorId,
      comment: {
        comment,
        createdBy: userGivenName,
        createdByEmail: userEmail,
      },
    });
    setComment("");
  };

  const handleContactChange = (key: string, value: string) => {
    setInvestor((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="flex-row justify-content-space-between p-0">
        <h1 className="m-0">Create Angel Investor</h1>
      </div>
      <div>
        <ContactMethods
          value={investor.notificationMethod}
          onChange={(value) => handleContactChange("notificationMethod", value)}
        />
        <p className="title">Personal Information</p>
        <div className="input-grid">
          <TextInput
            name="name"
            type="text"
            value={investor.name}
            placeholder="Name"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="surname"
            type="text"
            value={investor.surname}
            placeholder="Surname"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="idNumber"
            type="number"
            value={investor.idNumber}
            placeholder="ID number"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="string"
            name="mobile"
            placeholder="Contact number"
            value={investor.mobile}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="email"
            name="email"
            placeholder="Email address"
            value={investor.email}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="occupation"
            type="text"
            value={investor.occupation}
            placeholder="Occupation"
            inputChanged={handleChange}
            validations={validations}
          />
        </div>

        {(roles.includes("Admin") || roles.includes("Admin")) && (
          <>
            <p className="title">Address Information</p>
            <div className="input-grid">
              <TextInput
                type="text"
                name="address1"
                placeholder="Street address"
                value={investor.address1}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address2"
                placeholder="Unit / Building no."
                value={investor.address2}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address3"
                placeholder="Suburb"
                value={investor.address3}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address4"
                placeholder="Province"
                value={investor.address4}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="number"
                name="address5"
                placeholder="Postal Code"
                value={investor.address5}
                inputChanged={handleChange}
                validations={validations}
              />
            </div>
          </>
        )}

        <div className="flex flex-row pl-0 mt-2">
          <button
            className="gray"
            onClick={(e) => {
              e.preventDefault();
              !!artistId && roles.includes("Artist")
                ? navigate(artistRoute.profile(artistId))
                : !!artistId && !roles.includes("Artist")
                ? navigate(adminRoute.Artist(artistId))
                : window.location.replace(
                    "http://www.artscapital.co.za/thank-you"
                  );
            }}
          >
            Back
          </button>
          <button type="submit" onClick={onSubmit}>
            {!submitting ? "Save" : <Loader />}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateArtistDonor;
