export const TikTok = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3108 3.3508C10.7639 2.72045 10.4352 1.89854 10.4352 1H9.74991M11.3108 3.3508C11.8092 3.92547 12.484 4.34341 13.2525 4.50706C13.4912 4.55931 13.7404 4.5872 14 4.5872V7.16433C12.671 7.16433 11.4387 6.73601 10.435 6.01158V11.253C10.435 13.872 8.3168 16 5.71754 16C4.35729 16 3.12859 15.4149 2.26677 14.485C1.4811 13.6353 1 12.4998 1 11.253C1 8.67235 3.05592 6.56882 5.60331 6.51309M11.3108 3.3508C11.2975 3.34207 11.2842 3.33326 11.271 3.32436M3.97316 12.5138C3.71704 12.1586 3.56475 11.7233 3.56475 11.2496C3.56475 10.055 4.5304 9.08335 5.7176 9.08335C5.9391 9.08335 6.15367 9.1216 6.35442 9.1843V6.55144C6.14674 6.52355 5.93564 6.50612 5.7176 6.50612C5.6795 6.50612 5.37283 6.52663 5.33475 6.52663M9.74625 1H7.87033L7.8669 11.3331C7.82535 12.4894 6.87698 13.4193 5.71754 13.4193C4.99761 13.4193 4.36421 13.0606 3.96965 12.5173"
        stroke="#4c4c4c"
        strokeWidth="1.29848"
        strokeLinejoin="round"
      />
    </svg>
  );
};
