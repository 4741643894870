import { apiSlice } from "../../app/api/apiSlice";
import { Investor, InvestorStatus } from "../../interface/investor";
import { PageSearchQuery, PageSearchResponse } from "../../interface/queries";
interface UpdateInvestor {
  artistId: number;
  investorId: number;
  userDetails?: Investor;
}
export const investorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    getArtistInvestors: builder.query<PageSearchResponse, PageSearchQuery>({
      query: (artistId) => "/investors/" + artistId,
      keepUnusedDataFor: 60,
      providesTags: ["Leads"],
    }),

    getDonors: builder.query<PageSearchResponse, PageSearchQuery>({
      query: () => "/investors/donors",
      keepUnusedDataFor: 60,
      providesTags: ["Donors"],
    }),
    getDonor: builder.query<Investor, any>({
      query: (donorId) => "/investors/donors/" + donorId,
      keepUnusedDataFor: 60,
      providesTags: ["Donors"],
    }),
    getInvestor: builder.query<Investor, UpdateInvestor>({
      query: ({ artistId, investorId }) =>
        "/investors/" + artistId + "/" + investorId,
      keepUnusedDataFor: 60,
      providesTags: ["Leads"],
    }),
    // POSTS
    createInvestor: builder.mutation<any, Investor>({
      query: (payload: any) => ({
        url: "/investors/" + payload.systemUserId,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Leads", "Artists", "users"],
    }),
    createDonor: builder.mutation<any, Investor>({
      query: (payload: any) => ({
        url: "/investors/donor",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Donors"],
    }),
    // PUTS
    updateArtistInvestor: builder.mutation<any, Investor>({
      query: (payload: any) => ({
        url:
          "/investors/" +
          payload.systemUserId +
          "/" +
          payload.investorId,
          // payload.systemUserInvestorId,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Leads", "Artists", "users"],
    }),

    assignInvestor: builder.mutation<any, any>({
      query: (payload: any) => ({
        url:
          "/investors/donors/" +
          payload.investorId +
          "/assign/" +
          payload.artistId,
        method: "PUT",
      }),
      invalidatesTags: ["Leads", "Donors", "Artists", "users"],
    }),

    updateInvestorStatus: builder.mutation<any, any>({
      query: (payload: InvestorStatus) => ({
        url:
          "/investors/" +
          payload.systemUserId +
          "/" +
          payload.systemUserInvestorId +
          "/status",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Leads", "Artists", "users"],
    }),

    cancelInvestorSubscription: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "/investors/cancel-subscription/",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Leads", "Donors", "Artists", "users"],
    }),
    comment: builder.mutation<any, any>({
      query: (payload: any) => ({
        url:
          "/investors/" +
          payload.artistId +
          "/" +
          payload.investorId +
          "/comment",
        method: "PUT",
        body: payload.comment,
      }),
      invalidatesTags: ["Leads", "Artists", "users"],
    }),
    resolveComment: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "/investors/comments/resolve/",
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Leads", "Artists", "users"],
    }),
    revokeDebitOrder: builder.mutation<any, { artistId: any; investorId: any }>(
      {
        query: (payload) => ({
          url: `/investors/${payload.artistId}/${payload.investorId}/revoke-subscription`,
          method: "PUT",
        }),
        invalidatesTags: ["Leads", "Artists", "users"],
      }
    ),
  }),
});

export const {
  // GETS
  useGetArtistInvestorsQuery,
  useGetDonorsQuery,
  useGetDonorQuery,
  useGetInvestorQuery,
  // POSTS
  useCreateInvestorMutation,
  useCreateDonorMutation,
  //Puts
  useUpdateArtistInvestorMutation,
  useAssignInvestorMutation,
  useUpdateInvestorStatusMutation,
  useCancelInvestorSubscriptionMutation,
  useCommentMutation,
  useResolveCommentMutation,
  useRevokeDebitOrderMutation,
} = investorsApiSlice;
