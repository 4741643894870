import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import { routes } from "./routes";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  return (
    <>
      <ToastContainer />
      <RouterProvider router={routes} />
    </>
  );
};

export default App;
