import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import SearchableSelect from "../../components/searchableSelect/searchableSelect";
import TextInput from "../../components/textInput/textInput";
import {
  useAssignInvestorMutation,
  useGetDonorQuery,
} from "../../features/investors/investorsApiSlice";
import { useGetArtistsQuery } from "../../features/users/usersApiSlice";
import { AccountDetailsInterface } from "../../interface/account";
import { adminRoute } from "../../routes";
const EditDonor = () => {
  const navigate = useNavigate();
  const { donorId } = useParams<{ donorId: any }>();
  const [assignArtist, { isLoading: submitting }] = useAssignInvestorMutation();
  const { data: investor, isLoading } = useGetDonorQuery(donorId, {
    skip: !donorId,
  });

  const [pageCount, setPageCount] = useState<any>(0);
  const [term, setTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const { data: artists, isLoading: isArtistsLoading } = useGetArtistsQuery({
    pageSize,
    pageNumber,
    term,
  });

  const artistOptions = useMemo(() => {
    return (
      artists?.collection?.map((c: AccountDetailsInterface) => ({
        label: `${c.name} ${c.surname}`,
        value: c.systemUserId,
        thumbnail: c.profileImageUrl || "/user.png",
      })) ?? []
    );
  }, [artists]);

  const handleArtistSelected = (c: any) => {
    assignArtist({
      investorId: Number(donorId),
      artistId: Number(c.value),
    })
      .unwrap()
      .then(() => {
        navigate(adminRoute.Donors());
      })
      .catch(() => {
        toast.error("Could not assign artist to donor.", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  return (
    <>
      <div className="flex-row justify-content-space-between p-0">
        <h1>Donor Information</h1>
      </div>
      {!isLoading && investor && !isArtistsLoading ? (
        <div>
          <p className="title">Personal Information</p>
          <div className="input-grid">
            <TextInput
              name="name"
              type="text"
              value={investor.name}
              placeholder="Name"
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              name="surname"
              type="text"
              value={investor.surname}
              placeholder="Surname"
              inputChanged={() => {}}
              disabled
            />
            <TextInput
            name="idNumber"
            type="number"
            value={investor.idNumber}
            placeholder="ID number"
            inputChanged={() => {}}
            disabled
          />
            <TextInput
              type="string"
              name="mobile"
              placeholder="Contact number"
              value={investor.mobile}
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              type="email"
              name="email"
              placeholder="Email address"
              value={investor.email}
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              name="occupation"
              type="text"
              value={investor.occupation}
              placeholder="Occupation"
              inputChanged={() => {}}
              disabled
            />
          </div>
          <p className="title">Address Information</p>
          <div className="input-grid">
            <TextInput
              type="text"
              name="address1"
              placeholder="Street address"
              value={investor.address1}
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              type="text"
              name="address2"
              placeholder="Unit / Building no."
              value={investor.address2}
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              type="text"
              name="address3"
              placeholder="Suburb"
              value={investor.address3}
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              type="text"
              name="address4"
              placeholder="Province"
              value={investor.address4}
              inputChanged={() => {}}
              disabled
            />
            <TextInput
              type="number"
              name="address5"
              placeholder="Postal Code"
              value={investor.address5}
              inputChanged={() => {}}
              disabled
            />
          </div>

          <p className="title">Assigned Artist</p>
          <div className="input-grid">
            <SearchableSelect
              invalid={!investor.systemUserId}
              options={artistOptions}
              selectedValue={artistOptions.find(
                (c: any) => c.value === investor.systemUserId
              )}
              onSelectionChanged={handleArtistSelected}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default EditDonor;
