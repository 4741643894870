import { useEffect, useMemo, useRef, useState } from "react";
import "./creatableMultiSelect.scss";
import { toast } from "react-toastify";

interface option {
  value: any;
  label: string;
}

interface Multiselectorprops {
  label?: string;
  invalid?: boolean;
  selectedValues: option[];
  options: option[];
  defaultCreateId?: any;
  onSelectionChanged: (options: option[]) => void;
  onCreateOption: (options: option) => void;
}

const CreatableMultiSelect = (selector: Multiselectorprops) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [optionLs, setOptionLs] = useState<option[]>(selector.options ?? []);
  const [values, setValues] = useState<option[]>(selector.selectedValues ?? []);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setValues(selector.selectedValues);
    setOptionLs(selector.options);
  }, [selector.selectedValues]);

  const createOption = (e: option) => {
    if (values.some((c) => c.label === e.label)) {
      toast.warn(`Already have a selection of ${e.label}`);
      return;
    }
    selector.onCreateOption(e);
    selectOption(e);
    setShowOptions(false);
    setSearchTerm("");
  };
  const selectOption = (e: option) => {
    const newValues = values;
    newValues.push(e);
    setValues(newValues);
    selector.onSelectionChanged(newValues);
  };
  const removeOption = (e: option) => {
    const newValues = values.filter((c) => c.label !== e.label);
    setValues(newValues);
    selector.onSelectionChanged(newValues);
  };

  const options = useMemo(() => {
    const searchResults = selector.options.filter((c) =>
      c.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    if (searchTerm.length > 0 && searchResults.length === 0) {
      return (
        <li
          key={searchTerm + "_o"}
          onClick={() =>
            createOption({
              value: selector.defaultCreateId
                ? selector.defaultCreateId
                : searchTerm,
              label: searchTerm,
            })
          }
          value={searchTerm}
        >
          Create <strong>{searchTerm}</strong>
        </li>
      );
    } else {
      return searchResults.map((c) => {
        const selected = values.some(
          (x) => x.label === c.label || x.value === c.value
        );

        return (
          <li
            key={c.value + "_o"}
            className={selected ? "selector--selected" : ""}
            onClick={() => {
              if (!selected) selectOption(c);
              else removeOption(c);
            }}
            value={c.value}
          >
            {c.label}
          </li>
        );
      });
    }
  }, [searchTerm, optionLs]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    }

    if (showOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions]);
  return (
    <div className="selector" ref={dropdownRef}>
      {!!selector.label && <p className="label m-0">{selector.label}</p>}
      <div
        className={`selector--search--wrapper ${
          searchTerm.length > 0 || showOptions ? "selector--expanded" : ""
        }`}
      >
        <input
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholder="Search..."
          className={`selector--search--input ${
            selector.invalid ? "is-invalid" : ""
          }`}
        />
        {selector.invalid && (
          <div className="invalid-feedback">Please select a option</div>
        )}
        {searchTerm.length > 0 && (
          <div
            className="selector--clear--search"
            onClick={() => setSearchTerm("")}
          ></div>
        )}
        <div
          className="selector--show--hide"
          onClick={() => setShowOptions(!showOptions)}
        ></div>
      </div>
      <div className="selector--value--wrapper">
        {values.map((c) => (
          <span key={`${c.label}_${c.value}_v`} onClick={() => removeOption(c)}>
            {c.label}
          </span>
        ))}
      </div>
      <div className="selector--options--wrapper">
        {(searchTerm.length > 0 || showOptions) && (
          <ul className="selector--options">{options}</ul>
        )}
      </div>
    </div>
  );
};

export default CreatableMultiSelect;
