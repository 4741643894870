import React, { FormEvent, useState, useEffect, useMemo } from "react";
import TextInput from "../../components/textInput/textInput";
import { toast } from "react-toastify";
import { Validators } from "../../validators";
import {
  useCreateArtistSupplierMutation,
  useGetArtistSuppliersQuery,
  useGetSupplierQuery,
  useUpdateArtistSupplierMutation,
  useUpdateSupplierMutation,
} from "../../features/suppliers/suppliersApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Supplier } from "../../interface/supplier";
import Loader from "../../components/loader/loader";
import SearchableSelect from "../../components/searchableSelect/searchableSelect";
import { useGetArtistsQuery } from "../../features/users/usersApiSlice";
import { AccountDetailsInterface } from "../../interface/account";
import moment from "moment";

interface Event {
  artistSupplierId?: number;
  supplierId: number;
  artistId: number;
  visitationDate?: string;
  hours: string;
  minutes: string;
}

function SupplierEdit() {
  const navigate = useNavigate();
  const { supplierId } = useParams<{ supplierId: any }>();

  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [supplier, setSupplier] = useState<Supplier>({
    profession: "",
    firmName: "",
    name: "",
    surname: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    email: "",
    mobile: "",
    hoursAvailable: 0,
  } as unknown as Supplier);

  const [supplierEvent, setSupplierEvent] = useState<Event>({
    visitationDate: moment().format("DD-MM-YYYY"),
    supplierId: supplierId,
    artistId: 0,
    hours: "0",
    minutes: "0",
  } as unknown as Event);

  const { data, isLoading } = useGetSupplierQuery(supplierId, {
    skip: !supplierId,
  });
  const [updateSupplier, { isLoading: submitting }] =
    useUpdateSupplierMutation();
  const [createArtistSupplier, { isLoading: supplierSubmitting }] =
    useCreateArtistSupplierMutation();
  const [updateArtistSupplier, { isLoading: eventSubmitting }] =
    useUpdateArtistSupplierMutation();

  const [events, setEvents] = useState<Event[]>([]);

  const { data: artists, isLoading: isArtistsLoading } = useGetArtistsQuery({
    pageSize: 1000,
    pageNumber: 0,
    term: "",
  });

  const { data: suppliers, isLoading: isSuppliersLoading } =
    useGetArtistSuppliersQuery(supplierId);

  const artistOptions = useMemo(() => {
    return (
      artists?.collection?.map((c: AccountDetailsInterface) => ({
        label: `${c.name} ${c.surname}`,
        value: c.systemUserId,
        thumbnail: c.profileImageUrl || "/user.png",
      })) ?? []
    );
  }, [artists]);

  useEffect(() => {
    if (data) {
      const remaining = convertNumToTime(
        Number(data.hoursAvailable ?? 0) - Number(data.hoursConsumed ?? 0)
      );

      setSupplier({
        ...data,
        remainingHours: remaining.hours,
        remainingMinutes: remaining.minutes,
      });
      const time = convertNumToTime(data.hoursAvailable);
      setHours(time.hours);
      setMinutes(time.minutes);
    }

    return () => {};
  }, [data]);

  useEffect(() => {
    if (suppliers) {
      const events = suppliers.map((c: any) => {
        return {
          artistId: c.artistId,
          artistSupplierId: c.artistSupplierId,
          hours: convertNumToTime(c.hoursConsumed).hours,
          minutes: convertNumToTime(c.hoursConsumed).minutes,
          supplierId: c.supplierId,
          visitationDate: moment(c.visitationDate).format("DD-MM-YYYY"),
        };
      });
      setEvents(events);
    }
    return () => {};
  }, [suppliers]);

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    firmName: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    profession: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplier({ ...supplier, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(supplier, validations);
    setValidations({ ...validations });

    if (valid && supplier) {
      updateSupplier(supplier)
        .unwrap()
        .then(() => {
          toast.success("Service provider updated.", {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        })
        .catch(() => {
          toast.error("Failed to update supplier. Please try again later.", {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }

      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleEventChange = (e: any, index: number, identifier: string) => {
    const newEvents: Event[] = [...events];

    let val = identifier === "artist" ? e.value : e.target.value;

    if (identifier === "dateOfBirth") {
      if (val.length === 2 || val.length === 6) {
        val += "-";
      }
    }

    //@ts-ignore
    newEvents[index][identifier] = val;
    setEvents(newEvents);
  };

  const handleAddEvent = () => {
    if (
      !(
        !!supplierEvent.artistId &&
        Validators.validDate(supplierEvent.visitationDate)
      )
    ) {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }

      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
      return;
    }

    createArtistSupplier({
      artistId: supplierEvent.artistId,
      supplierId: supplierId,
      visitationDate: moment(supplierEvent.visitationDate, "DD-MM-YYYY")
        .add(1, "day")
        .utc(),
      hoursConsumed:
        (parseFloat(supplierEvent.minutes) || 0) / 60 +
        (parseFloat(supplierEvent.hours) || 0),
    })
      .then(() => {
        setSupplierEvent({
          visitationDate: moment().format("DD-MM-YYYY"),
          supplierId: supplierId,
          artistId: 0,
          hours: "0",
          minutes: "0",
        });
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  const convertNumToTime = (number: number) => {
    // Check sign of given number
    var sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + "";

    // Add padding if need
    if (minute.length < 2) {
      minute = "0" + minute;
    }

    return {
      hours: hour.toString(),
      minutes: minute,
    };
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hoursInput = parseFloat(e.target.value) || 0;
    if (hoursInput < 0) {
      setSupplier({
        ...supplier,
        hoursAvailable: (parseFloat(minutes) || 0) / 60 + 0,
      });
    } else
      setSupplier({
        ...supplier,
        hoursAvailable: (parseFloat(minutes) || 0) / 60 + hoursInput,
      });

    setHours(e.target.value);
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minutesInput = parseFloat(e.target.value) || 0;
    const minutesDecimal = minutesInput / 60;

    if (minutesInput > 59) {
      setSupplier({
        ...supplier,
        hoursAvailable: 59 / 60 + (parseFloat(hours) || 0),
      });
    } else if (minutesInput < 0) {
      setSupplier({
        ...supplier,
        hoursAvailable: parseFloat(hours) || 0,
      });
    } else
      setSupplier({
        ...supplier,
        hoursAvailable: (parseFloat(hours) || 0) + minutesDecimal,
      });

    setMinutes(e.target.value);
  };

  const updateEvent = (index: number) => {
    let event = events[index];
    if (
      !(
        !!event.artistSupplierId &&
        !!event.artistId &&
        Validators.validDate(event.visitationDate)
      )
    ) {
      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
      return;
    }

    updateArtistSupplier({
      artistSupplierId: event.artistSupplierId,
      artistId: event.artistId,
      supplierId: supplierId,
      visitationDate: moment(event.visitationDate, "DD-MM-YYYY")
        .add(1, "day")
        .utc(),
      hoursConsumed:
        (parseFloat(event.minutes) || 0) / 60 + (parseFloat(event.hours) || 0),
    })
      .then(() => {
        toast.success("Updated event", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  return (
    <>
      <div className="flex-row justify-content-space-between p-0">
        <h1>Edit service provider</h1>
      </div>
      {!isLoading && supplier ? (
        <div>
          <p className="title">Personal Information</p>
          <div className="input-grid">
          <TextInput
            name="name"
            type="text"
            value={supplier.name}
            placeholder="Name"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="surname"
            type="text"
            value={supplier.surname}
            placeholder="Surname"
            inputChanged={handleChange}
            validations={validations}
          />
            <TextInput
              type="string"
              name="mobile"
              placeholder="Contact number"
              value={supplier.mobile}
              inputChanged={handleChange}
              validations={validations}
            />
            <TextInput
              type="email"
              name="email"
              placeholder="Email address"
              value={supplier.email}
              inputChanged={handleChange}
              validations={validations}
            />

            <TextInput
              name="profession"
              type="text"
              value={supplier.profession}
              placeholder="Profession"
              inputChanged={handleChange}
              validations={validations}
            />
          </div>
          <p className="title">Business Information</p>
          <div className="input-grid">
            <TextInput
              name="firmName"
              type="text"
              value={supplier.firmName}
              placeholder="Firm name"
              inputChanged={handleChange}
              validations={validations}
            />
            <div>
              <div className="input-grid">
                <TextInput
                  name="hours"
                  type="number"
                  value={hours}
                  placeholder="Hours available"
                  inputChanged={handleHoursChange}
                  validations={{
                    hours: [
                      {
                        check: Validators.isPositive,
                        isValid: Validators.isPositive(hours),
                      },
                    ],
                  }}
                />
                <TextInput
                  name="minutes"
                  type="number"
                  value={minutes}
                  placeholder="Minutes available"
                  inputChanged={handleMinutesChange}
                  validations={{
                    minutes: [
                      {
                        check: Validators.isMinutes,
                        isValid: Validators.isMinutes(minutes),
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <p className="title">Address Information</p>
          <div className="input-grid">
            <TextInput
              type="text"
              name="address1"
              placeholder="Street address"
              value={supplier.address1}
              inputChanged={handleChange}
              validations={validations}
            />
            <TextInput
              type="text"
              name="address2"
              placeholder="Unit / Building no."
              value={supplier.address2}
              inputChanged={handleChange}
              validations={validations}
            />
            <TextInput
              type="text"
              name="address3"
              placeholder="Suburb"
              value={supplier.address3}
              inputChanged={handleChange}
              validations={validations}
            />
            <TextInput
              type="text"
              name="address4"
              placeholder="Province"
              value={supplier.address4}
              inputChanged={handleChange}
              validations={validations}
            />
            <TextInput
              type="number"
              name="address5"
              placeholder="Postal Code"
              value={supplier.address5}
              inputChanged={handleChange}
              validations={validations}
            />
          </div>

          <div className="flex flex-row pl-0 mt-2">
            <button
              className="gray"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Back
            </button>
            <button type="submit" onClick={handleSubmit}>
              {!submitting ? "Save" : <Loader />}
            </button>
          </div>

          {!isArtistsLoading && artistOptions?.length > 0 && (
            <>
              <div className="d-flex flex-row justify-content-space-between">
                <p className="title">Artist Events</p>
                <p className="title">
                  {supplier.remainingHours}h {supplier.remainingMinutes}m
                  Remaining
                </p>
              </div>
              {events.length > 0 ? (
                events.map((c, i) => (
                  <div
                    className="supplier-events-grid flex flex-row flex-wrap card mb-1"
                    key={`${c.artistSupplierId}_${i}`}
                  >
                    <TextInput
                      type="text"
                      name="visitationDate"
                      placeholder="Date (DD-MM-YYYY)"
                      value={c.visitationDate}
                      inputChanged={(e: any) =>
                        handleEventChange(e, i, "visitationDate")
                      }
                    />

                    <div>
                      <p className="label m-0">Artist</p>
                      <SearchableSelect
                        invalid={!c.artistId}
                        options={artistOptions}
                        selectedValue={artistOptions.find(
                          (x: any) => x.value === c.artistId
                        )}
                        onSelectionChanged={(e: any) =>
                          handleEventChange(e, i, "artistId")
                        }
                      />
                    </div>

                    <TextInput
                      type="number"
                      name="hours"
                      placeholder="Hours"
                      value={c.hours}
                      inputChanged={(e: any) =>
                        handleEventChange(e, i, "hours")
                      }
                      validations={{
                        hours: [
                          {
                            check: Validators.isPositive,
                            isValid: Validators.isPositive(hours),
                          },
                        ],
                      }}
                    />
                    <TextInput
                      type="number"
                      name="minutes"
                      placeholder="Minutes"
                      value={c.minutes}
                      inputChanged={(e: any) =>
                        handleEventChange(e, i, "minutes")
                      }
                      validations={{
                        minutes: [
                          {
                            check: Validators.isMinutes,
                            isValid: Validators.isMinutes(minutes),
                          },
                        ],
                      }}
                    />
                    <button
                      style={{
                        height: 53,
                        margin: "auto",
                        marginTop: "24px",
                      }}
                      onClick={(e: any) => updateEvent(i)}
                    >
                      {!eventSubmitting ? "Update" : <Loader />}
                    </button>
                  </div>
                ))
              ) : (
                <p>No events logged</p>
              )}

              <div className="supplier-events-grid flex flex-row flex-wrap card mb-1">
                <TextInput
                  type="text"
                  name="visitationDate"
                  placeholder="DD-MM-YYYY"
                  value={supplierEvent.visitationDate}
                  inputChanged={(e: any) =>
                    setSupplierEvent((prev) => {
                      let val = e.target.value;
                      if (val.length === 2 || val.length === 6) {
                        val += "-";
                      }
                      return { ...prev, visitationDate: val };
                    })
                  }
                  validations={{
                    visitationDate: [
                      {
                        check: Validators.validDate,
                        isValid: Validators.validDate(
                          supplierEvent.visitationDate
                        ),
                      },
                    ],
                  }}
                />

                <div>
                  <p className="label m-0">Artist</p>
                  <SearchableSelect
                    invalid={!supplierEvent.artistId}
                    options={artistOptions}
                    selectedValue={artistOptions.find(
                      (c: any) => c.value === supplierEvent.artistId
                    )}
                    onSelectionChanged={(e: any) =>
                      setSupplierEvent((prev) => {
                        return { ...prev, artistId: e.value };
                      })
                    }
                  />
                </div>

                <TextInput
                  type="number"
                  name="hours"
                  placeholder="Hours"
                  value={supplierEvent.hours}
                  inputChanged={(e: any) =>
                    setSupplierEvent((prev) => {
                      return { ...prev, hours: e.target.value };
                    })
                  }
                  validations={{
                    hours: [
                      {
                        check: Validators.isPositive,
                        isValid: Validators.isPositive(hours),
                      },
                    ],
                  }}
                />
                <TextInput
                  type="number"
                  name="minutes"
                  placeholder="Minutes"
                  value={supplierEvent.minutes}
                  inputChanged={(e: any) =>
                    setSupplierEvent((prev) => {
                      return { ...prev, minutes: e.target.value };
                    })
                  }
                  validations={{
                    minutes: [
                      {
                        check: Validators.isMinutes,
                        isValid: Validators.isMinutes(minutes),
                      },
                    ],
                  }}
                />
                <button
                  style={{
                    height: 53,
                    margin: "auto",
                    marginTop: "24px",
                  }}
                  onClick={handleAddEvent}
                >
                  {!supplierSubmitting ? "Add" : <Loader />}
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default SupplierEdit;
