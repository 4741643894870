import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  // baseUrl: "https://localhost:7223/api/",
  baseUrl: "https://api.prod.artscapital.co.za/api/",
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  // TODO: check this to refresh
  //@ts-ignore
  if (result?.error?.status === "FETCH_ERROR") {
    api.dispatch(logOut());
    // console.log("sending refresh token");
    // // send refresh token to get new access token
    // const refreshResult = await baseQuery("/refresh", api, extraOptions);
    // console.log(refreshResult);
    // if (refreshResult?.data) {
    //   const user = api.getState().auth;
    //   // store the new token
    //   api.dispatch(setCredentials({ ...refreshResult.data, user }));
    //   // retry the original query with new access token
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logOut());
    // }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: ["Admins", "Artists", "Leads", "Donors", "EmailTemplates", "users"],
});
