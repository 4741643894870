import { useEffect } from "react";
import "../../../styles/modal.scss";

interface ModalProps {
  setOpenModal: () => void;
  title: string;
  content: string;
}
const EmailPreviewModal = ({ setOpenModal, title, content }: ModalProps) => {
  useEffect(() => {
    let html = content;
    html = html.replace("\n", "");
    //@ts-ignore
    document.getElementById("htmlcontent").innerHTML = html;
    return () => {};
  }, []);

  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{ width: "60rem" }}>
        <div id="htmlcontent"></div>
        <div className="flex-row mt-1 p-0 justify-content-center">
          <button className="gray" onClick={setOpenModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailPreviewModal;
