import { useState } from "react";

interface ModalProps {
  setOpenModal: () => void;
  title: string;
  onSubmit: (value: string) => void;
}
const ReasonModal = ({ setOpenModal, title, onSubmit }: ModalProps) => {
  const [reason, setReason] = useState<string>("");

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="modalHeader">
          <div className="titleCloseBtn">
            <button onClick={setOpenModal}>x</button>
          </div>
        </div>
        <div className="title">
          <h1>{title}</h1>
        </div>
        <form
          className="body mt-1"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(reason);
          }}
        >
          <p>Please provide a detailed reason:</p>

          <textarea
            className="brand-input"
            placeholder="Reason.."
            value={reason}
            required
            onChange={(e) => setReason(e.target.value)}
          />
          <div className="footer mt-2">
            <button type="button" className="error" onClick={setOpenModal}>
              Cancel
            </button>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReasonModal;
