import { useState } from "react";
import TextInput from "../textInput/textInput";
import { InvestorStatus, InvestorStatusEnum } from "../../interface/investor";

interface ModalProps {
  setOpenModal: () => void;
  status: InvestorStatus;
  onSubmit: (value: InvestorStatus) => void;
}
const StatusModal = ({ setOpenModal, status, onSubmit }: ModalProps) => {
  const [statusObj, setStatusObj] = useState<InvestorStatus>(status);
  const investorStatusValues = Object.values(InvestorStatusEnum);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debugger
    const { name, value } = e.target;

    setStatusObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="modalHeader">
          <div className="titleCloseBtn">
            <button className="p-0" onClick={setOpenModal}>
              x
            </button>
          </div>
        </div>
        <div className="title">
          <h1>Update status</h1>
        </div>
        <form
          className="body mt-1"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(statusObj);
          }}
        >
          <select
            name="status"
            defaultValue={statusObj.status}
            onChange={(e: any) => handleChange(e)}
          >
            {investorStatusValues.map((status) => (
              <option key={status} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </option>
            ))}
          </select>
          <br />
          <br />
          <TextInput
            name="reason"
            type="textarea"
            value={statusObj.reason}
            placeholder="Reason"
            inputChanged={handleChange}
          />
          <div className="footer mt-2">
            <button type="button" className="error" onClick={setOpenModal}>
              Cancel
            </button>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StatusModal;
