import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import TextInput from "../../components/textInput/textInput";
import { Supplier } from "../../interface/supplier";
import { Validators } from "../../validators";
import { useCreateSupplierMutation } from "../../features/suppliers/suppliersApiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { adminRoute } from "../../routes";

const SupplierRegister = () => {
  const { roles } = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");

  const [supplier, setSupplier] = useState<Supplier>({
    profession: "",
    firmName: "",
    name: "",
    surname: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    email: "",
    mobile: "",
    hoursAvailable: 0,
  } as unknown as Supplier);

  const [addSupplier, { isLoading: submitting }] = useCreateSupplierMutation();

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    firmName: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    profession: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplier({ ...supplier, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(supplier, validations);
    setValidations({ ...validations });

    if (
      valid &&
      Validators.isMinutes(minutes) &&
      Validators.isPositive(hours)
    ) {
      addSupplier(supplier)
        .unwrap()
        .then(() => {
          if (roles?.includes("Admin")) navigate(adminRoute.ServiceProviders());
          else
            window.location.replace("http://www.artscapital.co.za/thank-you");
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }

      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hoursInput = parseFloat(e.target.value) || 0;
    if (hoursInput < 0) {
      setSupplier({
        ...supplier,
        hoursAvailable: (parseFloat(minutes) || 0) / 60 + 0,
      });
    } else
      setSupplier({
        ...supplier,
        hoursAvailable: (parseFloat(minutes) || 0) / 60 + hoursInput,
      });

    setHours(e.target.value);
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minutesInput = parseFloat(e.target.value) || 0;
    const minutesDecimal = minutesInput / 60;

    if (minutesInput > 59) {
      setSupplier({
        ...supplier,
        hoursAvailable: 59 / 60 + (parseFloat(hours) || 0),
      });
    } else if (minutesInput < 0) {
      setSupplier({
        ...supplier,
        hoursAvailable: parseFloat(hours) || 0,
      });
    } else
      setSupplier({
        ...supplier,
        hoursAvailable: (parseFloat(hours) || 0) + minutesDecimal,
      });

    setMinutes(e.target.value);
  };

  return (
    <>
      <div className="flex-row justify-content-space-between p-0">
        <h1>
          {roles?.includes("Admin") ? "Create" : "Become a"} service provider
        </h1>
      </div>
      <div>
        <p className="title">Personal Information</p>
        <div className="input-grid">
          <TextInput
            name="name"
            type="text"
            value={supplier.name}
            placeholder="Name"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            name="surname"
            type="text"
            value={supplier.surname}
            placeholder="Surname"
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="string"
            name="mobile"
            placeholder="Contact number"
            value={supplier.mobile}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="email"
            name="email"
            placeholder="Email address"
            value={supplier.email}
            inputChanged={handleChange}
            validations={validations}
          />

          <TextInput
            name="profession"
            type="text"
            value={supplier.profession}
            placeholder="Profession"
            inputChanged={handleChange}
            validations={validations}
          />
        </div>
        <p className="title">Business Information</p>
        <div className="input-grid">
          <TextInput
            name="firmName"
            type="text"
            value={supplier.firmName}
            placeholder="Firm name"
            inputChanged={handleChange}
            validations={validations}
          />

          <div>
            <div className="input-grid">
              <TextInput
                name="hours"
                type="number"
                value={hours}
                placeholder="Hours available"
                inputChanged={handleHoursChange}
                validations={{
                  hours: [
                    {
                      check: Validators.isPositive,
                      isValid: Validators.isPositive(hours),
                    },
                  ],
                }}
              />
              <TextInput
                name="minutes"
                type="number"
                value={minutes}
                placeholder="Minutes available"
                inputChanged={handleMinutesChange}
                validations={{
                  minutes: [
                    {
                      check: Validators.isMinutes,
                      isValid: Validators.isMinutes(minutes),
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <p className="title">Address Information</p>
        <div className="input-grid">
          <TextInput
            type="text"
            name="address1"
            placeholder="Street address"
            value={supplier.address1}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address2"
            placeholder="Unit / Building no."
            value={supplier.address2}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address3"
            placeholder="Suburb"
            value={supplier.address3}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="text"
            name="address4"
            placeholder="Province"
            value={supplier.address4}
            inputChanged={handleChange}
            validations={validations}
          />
          <TextInput
            type="number"
            name="address5"
            placeholder="Postal Code"
            value={supplier.address5}
            inputChanged={handleChange}
            validations={validations}
          />
        </div>

        <div className="flex flex-row pl-0 mt-2">
          {roles?.includes("Admin") && (
            <button
              className="gray"
              onClick={(e) => {
                e.preventDefault();
                navigate(adminRoute.ServiceProviders());
              }}
            >
              Back
            </button>
          )}
          <button type="submit" onClick={onSubmit}>
            {!submitting ? (
              roles?.includes("Admin") ? (
                "Create"
              ) : (
                "Register"
              )
            ) : (
              <Loader />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SupplierRegister;
