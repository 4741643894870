import React, { FormEvent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BenefitsDetails, {
  BenefitsChange,
} from "../../components/benefits/benefitsDetails";
import ContactMethods from "../../components/contactMethods/contactMethods";
import CreatableMultiSelect from "../../components/creatableMultiSelect/CreatableMultiSelect";
import ImageUpload from "../../components/imageUpload/imageUpload";
import Loader from "../../components/loader/loader";
import TextInput from "../../components/textInput/textInput";
import { useGetArtistTypesQuery } from "../../features/artistType/artistTypeApiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useGetInstrumentsQuery } from "../../features/instruments/instrumentsApiSlice";
import {
  useGetArtistQuery,
  useUpdateArtistMutation,
} from "../../features/users/usersApiSlice";
import {
  AccountDetailRequestInterface,
  ArtistType,
  Benefits,
  BenefitsEnum,
  InstrumentType,
  NotificationMethod,
} from "../../interface/account";
import { adminRoute, artistRoute } from "../../routes";
import { Validators } from "../../validators";
const EditArtist = () => {
  const navigate = useNavigate();
  const { artistId } = useParams<{ artistId: any }>();
  const { roles } = useSelector(selectCurrentUser);
  const [updateArtist, { isLoading: submitting }] = useUpdateArtistMutation();
  const { data: userDetails, isLoading } = useGetArtistQuery(artistId, {
    skip: !artistId,
  });

  const { data: instrumentsList } = useGetInstrumentsQuery({});
  const { data: artistTypeList } = useGetArtistTypesQuery({});

  const BENEFITS: Benefits[] = [
    {
      artistBenefitId: 0,
      benefit: BenefitsEnum.FUNERAL_COVER,
      companyName: "",
      companyContact: "",
      policyNumber: "",
      amount: 0,
      index: 0,
    },
    {
      artistBenefitId: 0,
      benefit: BenefitsEnum.LEGAL_ADVICE,
      companyName: "",
      companyContact: "",
      policyNumber: "",
      amount: 0,
      index: 1,
    },
    {
      artistBenefitId: 0,
      benefit: BenefitsEnum.MEDICAL_AID,
      companyName: "",
      companyContact: "",
      policyNumber: "",
      amount: 0,
      index: 2,
    },
    {
      artistBenefitId: 0,
      benefit: BenefitsEnum.SAVINGS_FUND,
      companyName: "",
      companyContact: "",
      policyNumber: "",
      amount: 0,
      index: 3,
    },
    {
      artistBenefitId: 0,
      benefit: BenefitsEnum.PENSION_FUND,
      companyName: "",
      companyContact: "",
      policyNumber: "",
      amount: 0,
      index: 4,
    },
    {
      artistBenefitId: 0,
      benefit: BenefitsEnum.LIFE_COVER,
      companyName: "",
      companyContact: "",
      policyNumber: "",
      amount: 0,
      index: 5,
    },
  ];

  const [artist, setArtist] = useState<AccountDetailRequestInterface>({
    name: "",
    surname: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    idNumber: "",
    profileImageUrl: null,
    email: "",
    mobile: "",
    artistTypes: null,
    instrumentTypes: null,
    facebookHandle: "",
    twitterHandle: "",
    instagramHandle: "",
    tikTokHandle: "",
    benefits: [],
    pensionFund: 0,
    medicalAid: 0,
    savings: 0,
    lifeCover: 0,
    funeralCover: 0,
    legalCover: 0,
    approved: false,
    suspended: false,
    suspendReason: "",
    notificationMethod: NotificationMethod[NotificationMethod.Email],
  });

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    idNumber: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isID,
        isValid: true,
      },
    ],
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    artistTypes: [
      {
        check: Validators.hasLength,
        isValid: true,
      },
    ],
    instrumentTypes: [
      {
        check: Validators.hasLength,
        isValid: true,
      },
    ],
  });

  const handleContactChange = (key: string, value: string) => {
    setArtist((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setArtist((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useMemo(() => {
    if (userDetails) {
      setArtist({
        ...userDetails,
        benefits:
          userDetails.benefits.length === 0 ? BENEFITS : userDetails.benefits,
      });
    }
  }, [userDetails]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(artist, validations);
    setValidations({ ...validations });

    if (valid) {
      updateArtist(artist)
        .unwrap()
        .then(() => {
          roles.includes("Admin")
            ? navigate(adminRoute.Artist(artistId))
            : navigate(artistRoute.profile(artistId));
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }
      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleInstrumentSelect = (instruments: any) => {
    const instrumentTypes: InstrumentType[] = [];

    instruments.forEach((c: any) => {
      const instrument = instrumentsList?.find(
        (x: any) =>
          x.instrumentTypeId === c.value &&
          x.description.toLocaleLowerCase() !== "other"
      );
      if (instrument && instrument.instrumentTypeId !== 10) {
        instrumentTypes.push({
          instrumentTypeId: instrument.instrumentTypeId,
          description: null,
        });
      } else {
        instrumentTypes.push({ instrumentTypeId: 10, description: c.label });
      }
    });

    setArtist({
      ...artist,
      instrumentTypes: instrumentTypes,
    });

    validations.instrumentTypes?.forEach((c) => {
      c.isValid = c.check(instrumentTypes);
    });
  };

  const handleArtistTypeSelect = (types: any) => {
    const artistTypes: ArtistType[] = [];
    types.forEach((c: any) => {
      const artistType = artistTypeList?.find(
        (x: any) =>
          x.artistTypeId === c.value &&
          x.description.toLocaleLowerCase() !== "other"
      );
      if (artistType) {
        artistTypes.push({
          artistTypeId: artistType.artistTypeId,
          description: null,
        });
      } else {
        artistTypes.push({ artistTypeId: 4, description: c.label });
      }
    });

    setArtist({
      ...artist,
      artistTypes: artistTypes,
    });

    validations.artistTypes?.forEach((c) => {
      c.isValid = c.check(artistTypes);
    });
  };

  const isMusician = useMemo(() => {
    if (artistTypeList) {
      const musicianType = artistTypeList.find(
        (c: any) => c.description.toLocaleLowerCase() === "musician"
      );
      if (musicianType) {
        return artist.artistTypes?.some(
          (c) => c.artistTypeId === musicianType.artistTypeId
        );
      }
    }
    return false;
  }, [artist.artistTypes, artistTypeList]);

  useMemo(() => {
    if (isMusician)
      validations.instrumentTypes = [
        {
          check: Validators.hasLength,
          isValid: true,
        },
      ];
    else validations.instrumentTypes = [];
  }, [isMusician, artist]);

  const updateBenefits = ({
    key,
    value,
    objectKey,
  }: {
    key: BenefitsEnum;
    objectKey: keyof Benefits;
    value: string | number;
  }) => {
    let benefits = artist.benefits.slice();
    let objectToUpdate = benefits.find((x) => x.benefit === key);

    if (objectToUpdate) {
      let index = benefits.indexOf(objectToUpdate);
      objectToUpdate = { ...objectToUpdate, [objectKey]: value };
      benefits[index] = objectToUpdate;
      return setArtist({ ...artist, benefits: benefits });
    }
    return;
  };

  return (
    <>
      <h1 className="mb-2">Edit Profile</h1>
      {isLoading || !userDetails.systemUserId ? (
        <Loader />
      ) : (
        <>
          <ImageUpload
            entityId={userDetails.systemUserId}
            img={artist.profileImageUrl}
          />

          <ContactMethods
            value={artist.notificationMethod}
            onChange={(value) =>
              handleContactChange("notificationMethod", value)
            }
          />
          <div>
            <p className="title">Personal Information</p>
            <div className="input-grid">
              <TextInput
                type="text"
                name="name"
                placeholder="Name"
                value={artist.name}
                disabled={roles?.includes("Artist")}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="surname"
                placeholder="Surname"
                value={artist.surname}
                disabled={roles?.includes("Artist")}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="string"
                name="mobile"
                placeholder="Contact number"
                value={artist.mobile}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="email"
                name="email"
                placeholder="Email address"
                value={artist.email}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="number"
                name="idNumber"
                placeholder="ID number"
                value={artist.idNumber}
                disabled={roles?.includes("Artist")}
                inputChanged={handleChange}
                validations={validations}
              />
            </div>

            <p className="title">Address Information</p>
            <div className="input-grid">
              <TextInput
                type="text"
                name="address1"
                placeholder="Street address"
                value={artist.address1}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address2"
                placeholder="Unit / Building no."
                value={artist.address2}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address3"
                placeholder="Suburb"
                value={artist.address3}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address4"
                placeholder="Province"
                value={artist.address4}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="number"
                name="address5"
                placeholder="Postal Code"
                value={artist.address5}
                inputChanged={handleChange}
                validations={validations}
              />
            </div>

            <p className="title">Artist Information</p>
            <div className="input-grid">
              <CreatableMultiSelect
                label="Artist type"
                invalid={validations.artistTypes.some((c) => !c.isValid)}
                defaultCreateId={4}
                options={
                  artistTypeList
                    ?.filter(
                      (x: any) => x.description.toLocaleLowerCase() !== "other"
                    )
                    ?.map((c: any) => ({
                      label: c.description,
                      value: c.artistTypeId,
                    })) ?? []
                }
                onCreateOption={() => {}}
                selectedValues={
                  artist.artistTypes?.map((c: any) => ({
                    label:
                      artistTypeList?.find(
                        (x: any) =>
                          x.artistTypeId === c.artistTypeId &&
                          c.artistTypeId !== 4
                      )?.description ?? c.description,
                    value: c.artistTypeId,
                  })) ?? []
                }
                onSelectionChanged={handleArtistTypeSelect}
              />

              {isMusician && (
                <CreatableMultiSelect
                  label="Instrument type"
                  invalid={validations.instrumentTypes.some((c) => !c.isValid)}
                  defaultCreateId={10}
                  options={
                    instrumentsList
                      ?.filter(
                        (x: any) =>
                          x.description.toLocaleLowerCase() !== "other"
                      )
                      ?.map((c: any) => ({
                        label: c.description,
                        value: c.instrumentTypeId,
                      })) ?? []
                  }
                  onCreateOption={() => {}}
                  selectedValues={
                    artist.instrumentTypes?.map((c) => ({
                      label:
                        instrumentsList?.find(
                          (x: any) =>
                            x.instrumentTypeId === c.instrumentTypeId &&
                            c.instrumentTypeId !== 10
                        )?.description ??
                        c.description ??
                        "",
                      value: c.instrumentTypeId,
                    })) ?? []
                  }
                  onSelectionChanged={handleInstrumentSelect}
                />
              )}
            </div>

            <p className="title">Social Media</p>
            <div className="input-grid">
              <div>
                <p className="label m-0">Facebook handle</p>

                <div className="handle-wrapper">
                  <div className="hint">
                    <span>facebook.com/</span>
                  </div>
                  <input
                    value={artist?.facebookHandle ? artist?.facebookHandle : ""}
                    name="facebookHandle"
                    onChange={handleChange}
                    className=""
                    placeholder="Facebook handle"
                  ></input>
                </div>
              </div>
              <div>
                <p className="label m-0">Instagram handle</p>

                <div className="handle-wrapper">
                  <div className="hint">
                    <span>instagram.com/</span>
                  </div>
                  <input
                    value={
                      artist?.instagramHandle ? artist?.instagramHandle : ""
                    }
                    name="instagramHandle"
                    onChange={handleChange}
                    className=""
                    placeholder="Instagram handle"
                  ></input>
                </div>
              </div>
              <div>
                <p className="label m-0">TikTok handle</p>

                <div className="handle-wrapper">
                  <div className="hint">
                    <span>tiktok.com/@</span>
                  </div>
                  <input
                    value={artist?.tikTokHandle ? artist?.tikTokHandle : ""}
                    name="tikTokHandle"
                    onChange={handleChange}
                    className=""
                    placeholder="TikTok handle"
                  ></input>
                </div>
              </div>

              <div>
                <p className="label m-0">Twitter handle</p>

                <div className="handle-wrapper">
                  <div className="hint">
                    <span>twitter.com/</span>
                  </div>
                  <input
                    value={artist?.twitterHandle ? artist?.twitterHandle : ""}
                    name="twitterHandle"
                    onChange={handleChange}
                    className=""
                    placeholder="Twitter handle"
                  ></input>
                </div>
              </div>
            </div>

            {roles?.includes("SuperAdmin") || roles?.includes("Admin") ? (
              <>
                {artist?.benefits?.map((benefitObject) => (
                  <BenefitsDetails
                    key={`${benefitObject.artistBenefitId}_${benefitObject.benefit}`}
                    onFieldChange={(data: BenefitsChange) => {
                      updateBenefits({
                        key: benefitObject.benefit,
                        value: data.value,
                        objectKey: data.subKey,
                      });
                    }}
                    values={benefitObject}
                    sectionTitle={benefitObject.benefit}
                  />
                ))}
              </>
            ) : null}

            <div className="flex flex-row pl-0 mt-2">
              <button
                className="gray"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    roles?.includes("SuperAdmin") || roles?.includes("Admin")
                      ? adminRoute.Artist(artistId)
                      : artistRoute.profile(artistId)
                  );
                }}
              >
                Back
              </button>
              <button type="submit" onClick={handleSubmit}>
                {!submitting ? "Save" : <Loader />}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditArtist;
