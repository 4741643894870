import { FormEvent, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../features/users/usersApiSlice";
import { sharedRoute } from "../routes";
import { Validators } from "../validators";
import Loader from "../components/loader/loader";
import { ViewSvg } from "../components/svg/view";

interface FirstLoginInterface {
  otp: string;
  key: string;
  token: string;
  password: string;
}

const SetupPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [firstLogin, setFirstLogin] = useState<FirstLoginInterface>({
    key: searchParams.get("key"),
    token: searchParams.get("token"),
    otp: searchParams.get("otp"),
  } as FirstLoginInterface);
  const [pass, setPass] = useState<string>("");
  const [passwordType, setPasswordType] = useState<string>("password");

  const [setPassword, { isLoading: submitting }] = useResetPasswordMutation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFirstLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [validations, setValidation] = useState({
    password: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.hasCapital,
        isValid: true,
      },
      {
        check: Validators.hasEightChars,
        isValid: true,
      },
      {
        check: Validators.hasDigit,
        isValid: true,
      },
      {
        check: Validators.hasSpecial,
        isValid: true,
      },
      {
        check: Validators.hasLower,
        isValid: true,
      },
    ],
  });

  const hasEight = useMemo(() => Validators.hasEightChars(pass), [pass]);
  const hasCapital = useMemo(() => Validators.hasCapital(pass), [pass]);
  const hasSpecial = useMemo(() => Validators.hasSpecial(pass), [pass]);
  const hasLower = useMemo(() => Validators.hasLower(pass), [pass]);
  const hasDigit = useMemo(() => Validators.hasDigit(pass), [pass]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    const verifyPasswordInput = document.getElementById(
      "verifyPassword"
    ) as HTMLInputElement;

    if (passwordInput.value !== verifyPasswordInput.value) {
      toast.warn("Password does not match!", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    } else {
      if (validations.password.every((c) => c.isValid))
        setPassword(firstLogin)
          .unwrap()
          .then(() => navigate(sharedRoute.Login()))
          .catch((error) => {
            toast.error(error.data.message, {
              position: "top-right",
              autoClose: 5000,
              theme: "colored",
              pauseOnFocusLoss: false,
              pauseOnHover: false,
            });
          });
    }
  };

  return (
    <>
      <div className="p-0 login fullscreen flex align-items-center justify-content-center">
        <div className="card curved login-card shadowed">
          <div className="login-form flex align-items-center justify-content-center">
            <div className="flex-column width-100 align-items-center">
              <img
                src="./Arts-Capital-logo.png"
                width={200}
                alt="login"
                className="mb-2"
              />

              <h3>Set your password</h3>

              <div className="width-100">
                <div className="password-wrapper">
                  <input
                    placeholder="Enter a password"
                    id="password"
                    type={passwordType}
                    onChange={(e) => setPass(e.target.value)}
                    required
                  />

                  <div
                    onClick={() =>
                      setPasswordType(
                        passwordType === "text" ? "password" : "text"
                      )
                    }
                    className="pointer btn-password"
                  >
                    <ViewSvg />
                  </div>
                </div>

                <div className="pl-1">
                  <p
                    className={`m-0 hint ${
                      hasCapital ? "color-primary" : "color-error"
                    }`}
                  >
                    Has a capital letter
                  </p>
                  <p
                    className={`m-0 hint ${
                      hasLower ? "color-primary" : "color-error"
                    }`}
                  >
                    Has a lowecase letter
                  </p>
                  <p
                    className={`m-0 hint ${
                      hasDigit ? "color-primary" : "color-error"
                    }`}
                  >
                    Has a digit
                  </p>
                  <p
                    className={`m-0 hint ${
                      hasSpecial ? "color-primary" : "color-error"
                    }`}
                  >
                    Has a special character
                  </p>
                  <p
                    className={`m-0 hint ${
                      hasEight ? "color-primary" : "color-error"
                    }`}
                  >
                    Has 8 characters
                  </p>
                </div>

                <div className="mt-1 password-wrapper">
                  <input
                    placeholder="Verify your password"
                    name="password"
                    type={passwordType}
                    id="verifyPassword"
                    onChange={handleChange}
                    required
                  />

                  <div
                    onClick={() =>
                      setPasswordType(
                        passwordType === "text" ? "password" : "text"
                      )
                    }
                    className="pointer btn-password"
                  >
                    <ViewSvg />
                  </div>
                </div>
              </div>
              <button type="submit" className="width-100" onClick={onSubmit}>
                {!submitting ? "Set password" : <Loader />}
              </button>
            </div>
          </div>
          <div className="img-overlay">
            <img
              src="https://nuresvmgrpdiag.blob.core.windows.net/artscapital-website/login.png"
              className="login-bg-image"
              alt="login"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupPassword;
