import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { setCredentials, selectCurrentUser } from "../features/auth/authSlice";
import { useLoginMutation } from "../features/auth/authApiSlice";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Validators } from "../validators";
import TextInput from "../components/textInput/textInput";
import { adminRoute, artistRoute, sharedRoute } from "../routes";
import jwtDecode from "jwt-decode";
import Loader from "../components/loader/loader";
interface LoginRequest {
  email: string;
  password: string;
}
const Login = () => {
  const [loginForm, setLogin] = useState<LoginRequest>({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);

  const [login, { isLoading: submitting }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser.isLoggedIn) {
      navigateToRole();
    }
    return () => {};
  }, [currentUser]);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const valid = Validators.validateState(loginForm, validations);
      setValidations({ ...validations });

      if (valid) {
        const userData: any = await login(loginForm).unwrap();
        const decode: any = jwtDecode(userData.token);

        const email =
          decode[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
          ];

        const roles =
          decode[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ];

        const userId =
          decode[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ];
        const givenName =
          decode[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
          ];

        dispatch(
          setCredentials({
            isLoggedIn: true,
            accessToken: userData.token,
            // refreshToken: response.refreshToken,
            userId: userId,
            email: email,
            roles: roles,
            givenName: givenName,
          })
        );
      }
    } catch (error) {
      // Handle login error
      toast.error("The username or password you entered is incorrect.", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setLogin((data) => ({ ...data, [name]: value }));
  };

  const [validations, setValidations] = useState({
    password: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
  });

  const navigateToRole = () => {
    if (currentUser.roles.includes("SuperAdmin")) {
      if (location.state) {
        navigate(
          `${location.state.previousPage}${location.state.searchParams}`
        );
        return;
      }
      navigate(adminRoute.Artists());
      return;
    }

    if (currentUser.roles.includes("Admin")) {
      if (location.state) {
        navigate(
          `${location.state.previousPage}${location.state.searchParams}`
        );
        return;
      }
      navigate(adminRoute.Artists());
      return;
    }

    if (currentUser.roles.includes("Artist")) {
      if (location.state) {
        navigate(
          `${location.state.previousPage}${location.state.searchParams}`
        );
        return;
      }
      navigate(artistRoute.profile(currentUser.userId));
      return;
    }
  };

  return (
    <div className="p-0 login fullscreen flex align-items-center justify-content-center">
      <div className="card curved login-card shadowed">
        <div className="login-form flex align-items-center justify-content-center">
          <div className="flex-column width-100 align-items-center">
            <img
              src="./Arts-Capital-logo.png"
              width={200}
              alt="login"
              className="mb-2"
            />
            <TextInput
              type="email"
              name="email"
              placeholder="Email address"
              value={loginForm.email}
              inputChanged={handleChange}
              validations={validations}
            />
            <TextInput
              type="password"
              name="password"
              placeholder="Password"
              value={loginForm.password}
              inputChanged={handleChange}
              validations={validations}
            />
            <button className="width-100" onClick={handleLogin}>
              {!submitting ? "Login" : <Loader />}
            </button>
            <a
              href={sharedRoute.ForgotPassword()}
              className="text-align-center"
            >
              Forgot password
            </a>
          </div>
        </div>
        <div className="img-overlay">
          <img
            src="https://nuresvmgrpdiag.blob.core.windows.net/artscapital-website/login.png"
            className="login-bg-image"
            alt="login"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
