export const ViewSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="3.5" stroke="#454545" />
      <path d="M21 12C21 12 20 4 12 4C4 4 3 12 3 12" stroke="#454545" />
    </svg>
  );
};
