import { apiSlice } from "../../app/api/apiSlice";

export const paymentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    generateMandate: builder.mutation<any, any>({
      query: (payload: any) => ({
        url:
          "investors/" +
          payload.artistId +
          "/" +
          payload.investorId +
          "/mandate",
        method: "POST",
      }),
      invalidatesTags: ["Leads"],
    }),
  }),
});

export const { useGenerateMandateMutation } = paymentsApiSlice;
