import { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  useRemoveUserMediaMutation,
  useUserMediaMutation,
} from "../../features/users/usersApiSlice";
import "./imageUpload.scss";
import { CameraSvg } from "../svg/camera";

interface ImageUploadProps {
  entityId: any;
  img?: string | null;
  fullWidth?: boolean;
  float?: boolean;
}

const ImageUpload = ({ entityId, img, fullWidth, float }: ImageUploadProps) => {
  const supportedFileTypes = [
    "png",
    "pdf",
    "jpeg",
    "jpg",
    "webp",
    "image/png",
    "image/pdf",
    "image/jpeg",
    "image/jpg",
    "image/webp",
  ];
  const fileInputRef = useRef(null);
  const [image, setImage] = useState("");
  const [updateUserMedia, { isLoading: submitting }] = useUserMediaMutation();
  const [removeUserMedia, { isLoading }] = useRemoveUserMediaMutation();

  useMemo(() => setImage(!!img ? img : "/user.png"), [img]);

  const handleImageChange = (e: any) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    if (file) {
      if (!supportedFileTypes?.includes(file.type)) {
        toast.warn("Invalid file type. Only image files are allowed.", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });

        return;
      }

      const formData = new FormData();
      formData.append("formFile", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
        toast.success("Image uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
      };
      reader.readAsDataURL(file);

      updateUserMedia({ systemUserId: parseInt(entityId), media: formData })
        .unwrap()
        .then((payload) => {
          setImage(reader.result as string);
        })
        .catch((error) => {
          toast.error("Unable to update artist", {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    }
  };

  const triggerUpload = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleImageRemove = () => {
    removeUserMedia(parseInt(entityId));
  };

  return (
    <div className={`image-upload-wrapper ${!!fullWidth ? "full" : ""}`}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <div className="image-upload">
        <img src={!!image ? image : "/user.png"} alt="profile" />
        {!!img && (
          <button className="image-upload-remove" onClick={handleImageRemove}>
            Remove
          </button>
        )}
      </div>
      {!!float ? (
        <div className="image-upload-float" onClick={triggerUpload}>
          <CameraSvg />
        </div>
      ) : (
        <button className="image-upload-change" onClick={triggerUpload}>
          Change
        </button>
      )}
    </div>
  );
};

export default ImageUpload;
