import { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import Table from "../../components/dynamicTable/table";
import SearchBar from "../../components/search/search";
import { useGetDonorsQuery } from "../../features/investors/investorsApiSlice";
import { Investor } from "../../interface/investor";
import { PageSearchResponse } from "../../interface/queries";
import { adminRoute } from "../../routes";
import Loader from "../../components/loader/loader";
const Donors = () => {
  const [pageCount, setPageCount] = useState<any>(0);
  const [term, setTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();

  const { data: donors, isLoading } = useGetDonorsQuery({
    pageSize,
    pageNumber,
    term,
  });

  const mappedDonors: PageSearchResponse = useMemo(() => {
    if (donors) {
      setPageCount(donors.totalNumberOfPages);

      const mapped = donors?.collection?.map((c: Investor) => {
        return {
          ...c,
          name: `${c.name} ${c.surname}`,
        };
      });

      return {
        ...donors,
        collection: mapped,
      };
    }
    return {
      collection: [],
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pages: [],
    };
  }, [donors]);

  const handlePageClick = (data: any) => {
    let selected = data.selected;
    setPageNumber(selected);
  };
  const handleSearch = (e: any) => {
    setTerm(e.target.value);
  };

  const headers = [
    {
      header: "Full Name",
      identifier: "name",
      order: 0,
    },
    {
      header: "Email",
      identifier: "email",
      order: 1,
    },
    {
      header: "Mobile",
      identifier: "mobile",
      order: 2,
    },
    {
      header: "Status",
      identifier: "status",
      order: 3,
    },
  ];

  return (
    <div className="donors">
      <SearchBar placeholder={"Search..."} onChange={handleSearch} />
      <div className="flex-row justify-content-space-between align-items-center">
        <h1 className="mb-0">Donors</h1>
        {/* <a
          className="flex-row align-items-center header-link"
          href={adminRoute.CreateAdministrator()}
        >
          <AddSvg />
          Add administrator
        </a> */}
      </div>
      {!isLoading ? (
        <Table
          headers={headers}
          dataset={mappedDonors.collection}
          redirectFn={(data) =>
            navigate(adminRoute.EditDonor(data.investorId))
          }
        />
      ) : (
        <Loader />
      )}
      <div className="pagination-container">
        {mappedDonors && pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=""
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageCount}
            pageCount={pageCount}
            previousLabel=""
          />
        ) : null}
      </div>
    </div>
  );
};

export default Donors;
