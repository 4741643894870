import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

interface OtpData {
  timer: number;
  resetLink: boolean;
  key: string;
  token: string;
  success: boolean;
  loading: boolean;
}

interface OTPExpiry {
  message: string;
  success: boolean;
}

const defaultState: OtpData = {
  timer: 86400000,
  resetLink: false,
  key: "",
  token: "",
  success: true,
  loading: true,
};

export const otpSlice = createSlice({
  name: "otp",
  initialState: defaultState,
  reducers: {
    countdown: (state) => {
      return { ...state, timer: state.timer - 1000 };
    },
    setTokens: (state, action) => {
      const { token, key } = action.payload;
      if (state.key === key && state.token === token) {
        return {
          ...state,
          key,
          token,
        };
      }
      return { ...state, key: key, token: token, resetLink: false };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtpTimer.fulfilled, (state, action) => {
        const response: OTPExpiry = action.payload;
        if (!response.success) {
          return { ...state, success: false, loading: false, resetLink: false };
        }
        const expirtyDate = new Date(response.message);
        const dateNow = new Date();
        const timeRemaining =
          expirtyDate.getTime() +
          new Date().getTimezoneOffset() * 60000 -
          dateNow.getTime();
        return {
          ...state,
          timer: timeRemaining,
          success: true,
          loading: false,
          resetLink: false,
        };
      })
      .addCase(resetOtpLink.fulfilled, (state, action) => {
        if (action.payload === 200) {
          return { ...state, resetLink: true };
        }
        return { ...state, resetLink: false };
      });
  },
});

export const { countdown, setTokens } = otpSlice.actions;
export default otpSlice.reducer;

export const verifyOtpTimer = createAsyncThunk(
  "otp/verify",
  async (payload: any) => {
    try {
      const response = await axios.get(
        `https://api.prod.artscapital.co.za/api/users/otp-expire?key=${payload.key}&token=${payload.token}`
      );
      return response.data;
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  }
);

export const resetOtpLink = createAsyncThunk(
  "otp/reset",
  async (payload: { token: string; key: string }) => {
    try {
      const response = await axios.post(
        `https://api.prod.artscapital.co.za/api/users/resend-otp`,
        payload
      );
      return response.status;
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  }
);
export const otpState = createSelector(
  (state: any) => state.otp,
  (otp) => otp
);
