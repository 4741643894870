export const Facebook = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.416992 3.24935C0.416992 1.68454 1.68552 0.416016 3.25033 0.416016H11.7503C13.3151 0.416016 14.5837 1.68454 14.5837 3.24935V11.7493C14.5837 13.3141 13.3151 14.5827 11.7503 14.5827H3.25033C1.68552 14.5827 0.416992 13.3141 0.416992 11.7493V3.24935ZM3.25033 1.83268C2.46792 1.83268 1.83366 2.46695 1.83366 3.24935V11.7493C1.83366 12.5318 2.46792 13.166 3.25033 13.166H7.50033V8.20768H6.79199C6.40078 8.20768 6.08366 7.89056 6.08366 7.49935C6.08366 7.10814 6.40078 6.79102 6.79199 6.79102H7.50033V5.72852C7.50033 4.35931 8.61028 3.24935 9.97949 3.24935H10.4045C10.7957 3.24935 11.1128 3.56648 11.1128 3.95768C11.1128 4.34888 10.7957 4.66602 10.4045 4.66602H9.97949C9.39271 4.66602 8.91699 5.14171 8.91699 5.72852V6.79102H10.4045C10.7957 6.79102 11.1128 7.10814 11.1128 7.49935C11.1128 7.89056 10.7957 8.20768 10.4045 8.20768H8.91699V13.166H11.7503C12.5328 13.166 13.167 12.5318 13.167 11.7493V3.24935C13.167 2.46695 12.5328 1.83268 11.7503 1.83268H3.25033Z"
        fill="#4c4c4c"
      />
    </svg>
  );
};
