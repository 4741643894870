interface ModalProps {
  setOpenModal: () => void;
  title: string;
  onSubmit: () => void;
}
const ConfirmationModal = ({ setOpenModal, title, onSubmit }: ModalProps) => {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="modalHeader">
          <div className="titleCloseBtn">
            <button className="p-0" onClick={setOpenModal}>
              x
            </button>
          </div>
        </div>
        <div className="title">
          <h1>{title}</h1>
        </div>
        <div className="body mt-1">
          <div className="footer mt-2">
            <button type="button" className="error" onClick={setOpenModal}>
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
