import { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import { PageSearchResponse } from "../../interface/queries";
import { adminRoute } from "../../routes";
import Table from "../../components/dynamicTable/table";
import SearchBar from "../../components/search/search";
import { AddSvg } from "../../components/svg/add";
import { useGetArtistsQuery } from "../../features/users/usersApiSlice";
import { useGetArtistTypesQuery } from "../../features/artistType/artistTypeApiSlice";
import Loader from "../../components/loader/loader";
const Artists = () => {
  const [pageCount, setPageCount] = useState<any>(0);
  const [term, setTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();
  const { data: artistTypes } = useGetArtistTypesQuery({});
  const { data: artists, isLoading } = useGetArtistsQuery({
    pageSize,
    pageNumber,
    term,
  });

  const mappedArtists: PageSearchResponse = useMemo(() => {
    if (artists && !!artistTypes) {
      setPageCount(artists.totalNumberOfPages);
      const mappedCollection = artists.collection.map((artist: any) => {
        let types = artist.artistTypes?.map((type: any) => {
          if (!type.description) {
            const desc = artistTypes?.find(
              (c: any) => c.artistTypeId === type.artistTypeId
            )?.description;
            return (
              <p key={`${type.artistTypeId}_${desc}`} className="pill m-0">
                {desc}
              </p>
            );
          }

          return (
            <p
              key={`${type.artistTypeId}_${type.description}`}
              className="pill m-0"
            >
              {type.description}
            </p>
          );
        });
        return {
          ...artist,
          fullName: `${artist.name} ${artist.surname}`,
          artistTypes: <div className="flex-row flex-wrap p-0">{types}</div>,
          attended: !artist.attended ? (
            <svg
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.07046 10.26C7.96453 7.86801 9.19303 7.03601 11.2132 7.03601C13.2334 7.03601 14.4619 7.86801 15.356 10.26C15.3728 10.3055 15.3862 10.3524 15.396 10.4L16.5543 15.917C16.6155 16.2008 16.5485 16.4978 16.3719 16.7251C16.1953 16.9525 15.928 17.0858 15.6446 17.088H13.3173C13.37 17.6989 13.1737 18.3048 12.7751 18.7618C12.3764 19.2188 11.811 19.4861 11.2132 19.5C10.6151 19.4861 10.0494 19.2186 9.65063 18.7611C9.25191 18.3037 9.05587 17.6972 9.10918 17.086H6.78186C6.49847 17.0838 6.2312 16.9505 6.0546 16.7231C5.878 16.4958 5.81096 16.1988 5.87218 15.915L7.03048 10.4C7.04027 10.3524 7.05363 10.3055 7.07046 10.26Z"
                  stroke="#a8b147"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M9.10921 16.336C8.69499 16.336 8.35921 16.6718 8.35921 17.086C8.35921 17.5002 8.69499 17.836 9.10921 17.836V16.336ZM13.3173 17.836C13.7315 17.836 14.0673 17.5002 14.0673 17.086C14.0673 16.6718 13.7315 16.336 13.3173 16.336V17.836ZM9.62888 4.75C9.21467 4.75 8.87888 5.08579 8.87888 5.5C8.87888 5.91421 9.21467 6.25 9.62888 6.25V4.75ZM12.7976 6.25C13.2118 6.25 13.5476 5.91421 13.5476 5.5C13.5476 5.08579 13.2118 4.75 12.7976 4.75V6.25ZM9.10921 17.836H13.3173V16.336H9.10921V17.836ZM9.62888 6.25H12.7976V4.75H9.62888V6.25Z"
                  fill="#a8b147"
                ></path>
              </g>
            </svg>
          ) : (
            ""
          ),
          profileImageUrl: (
            <img
              key={artist.systemUserId}
              alt="profileImage"
              src={
                !!artist.profileImageUrl ? artist.profileImageUrl : "/user.png"
              }
            />
          ),
        };
      });

      return { ...artists, collection: mappedCollection };
    }
    return {
      collection: [],
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pages: [],
    };
  }, [artists, artistTypes]);

  const handlePageClick = (data: any) => {
    let selected = data.selected;
    setPageNumber(selected);
  };
  const handleSearch = (e: any) => {
    setTerm(e.target.value);
  };

  const headers = [
    {
      header: "",
      identifier: "profileImageUrl",
      order: 0,
    },
    {
      header: "Full Name",
      identifier: "fullName",
      order: 1,
    },
    {
      header: "Email",
      identifier: "email",
      order: 2,
    },
    {
      header: "Mobile",
      identifier: "mobile",
      order: 3,
    },
    {
      header: "Types",
      identifier: "artistTypes",
      order: 4,
    },
    {
      header: "Status",
      identifier: "status",
      order: 5,
    },
    {
      header: "",
      identifier: "attended",
      order: 6,
    },
  ];

  return (
    <div className="artists">
      <SearchBar placeholder={"Search..."} onChange={handleSearch} />
      <div className="flex-row justify-content-space-between align-items-center flex-wrap">
        <h1 className="mb-0">Artists</h1>
        <a
          className="flex-row align-items-center header-link"
          href={adminRoute.CreateArtist()}
        >
          <AddSvg />
          Add Artist
        </a>
      </div>

      {!isLoading ? (
        <Table
          headers={headers}
          dataset={mappedArtists.collection}
          redirectFn={(data) => {
            navigate(adminRoute.Artist(data.systemUserId));
          }}
        />
      ) : (
        <Loader />
      )}

      <div className="pagination-container">
        {mappedArtists && pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=""
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageCount}
            pageCount={pageCount}
            previousLabel=""
          />
        ) : null}
      </div>
    </div>
  );
};

export default Artists;
