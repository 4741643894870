import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    getArtists: builder.query({
      query: (query) => ({
        url: `users`,
        method: "GET",
        params: query,
      }),
      keepUnusedDataFor: 60,
      providesTags: ["users"],
    }),
    getAdmins: builder.query({
      query: (query) => ({
        url: `users/admins`,
        method: "GET",
        params: query,
      }),
      keepUnusedDataFor: 60,
      providesTags: ["users"],
    }),
    getArtist: builder.query({
      query: (artistId) => "users/" + artistId,
      keepUnusedDataFor: 60,
      providesTags: ["users"],
    }),
    getAdmin: builder.query({
      query: (adminId) => "users/admins/" + adminId,
      keepUnusedDataFor: 60,
      providesTags: ["users"],
    }),
    getOtpExpire: builder.query({
      query: () => "users/otp-expire",
      keepUnusedDataFor: 60,
    }),
    // POSTS
    resendOtp: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/resend-otp",
        method: "POST",
        body: payload,
      }),
    }),
    verify: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/verify",
        method: "POST",
        body: payload,
      }),
    }),
    resetPassword: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/reset-password",
        method: "POST",
        body: payload,
      }),
    }),
    // PUTS
    updateArtist: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/" + payload.systemUserId,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["users"],
    }),
    updateAdmin: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/admin/" + payload.systemUserId,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["users"],
    }),
    suspendArtist: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/" + payload.systemUserId + "/suspend",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["users"],
    }),
    suspendAdmin: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/admin/" + payload.systemUserId + "/suspend",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["users"],
    }),
    attendToArtist: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/" + payload.systemUserId + "/attend",
        method: "PUT",
      }),
      invalidatesTags: ["users"],
    }),
    userMedia: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/" + payload.systemUserId + "/media",
        method: "PUT",
        formData: true,
        body: payload.media,
      }),
      invalidatesTags: ["users"],
    }),
    removeUserMedia: builder.mutation<any, any>({
      query: (systemUserId) => ({
        url: "users/" + systemUserId + "/media/remove",
        method: "PUT",
      }),
      invalidatesTags: ["users"],
    }),
  }),
});

export const {
  // GETS
  useGetArtistsQuery,
  useGetAdminsQuery,
  useGetArtistQuery,
  useGetAdminQuery,
  useGetOtpExpireQuery,
  // POSTS
  useResendOtpMutation,
  useVerifyMutation,
  useResetPasswordMutation,
  //Puts
  useUpdateArtistMutation,
  useUpdateAdminMutation,
  useSuspendArtistMutation,
  useSuspendAdminMutation,
  useAttendToArtistMutation,
  useUserMediaMutation,
  useRemoveUserMediaMutation,
} = usersApiSlice;
