import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/login";
import ProtectedRoute from "./components/protectedRoute";
import Administrators from "./pages/admin/administrators";
import Administrator from "./pages/admin/administrator";
import Artists from "./pages/admin/artists";
import AdminLayout from "./layout/adminLayout";
import EditAdministrator from "./pages/admin/editAdministrator";
import EditArtist from "./pages/artist/editArtist";
import Artist from "./pages/admin/artist";
import ArtistProfile from "./pages/artist/artistProfile";
import CreateAdministrator from "./pages/admin/createAdministrator";
import Donors from "./pages/donors/donors";
import NotFound from "./pages/NotFound/NotFound";
import Otp from "./pages/otp/otp";
import CreateArtist from "./pages/admin/createArtist";
import CreateArtistDonor from "./pages/artist/createArtistDonor";
import EditArtistDonor from "./pages/artist/editArtistDonor";
import ArtistLayout from "./layout/artistLayout";
import EmailBuilder from "./pages/admin/emailBuilder/emailBuilder";
import Emails from "./pages/admin/emailTemplates";
import SetupPassword from "./pages/setupPassword";
import CreateDonor from "./pages/donors/donorRegister";
import SharedLayout from "./layout/sharedLayout";
import EditDonor from "./pages/donors/donorEdit";
import Suppliers from "./pages/suppliers/suppliers";
import SupplierEdit from "./pages/suppliers/supplierEdit";
import SupplierRegister from "./pages/suppliers/supplierRegister";
import ForgotPassword from "./pages/forgotPassword";

export enum SharedRoutes {
  Login = "/login",
  ForgotPassword = "/forgot-password",
  Register = "/register",
  Otp = "/verify",
  InitialPassword = "/setup-password",
  ArtistApprove = "/user/approve",
  NotAllowed = "/not-allowed",
  DonorRegister = "/donor/register",
  ServiceProviderRegister = "/service-provider/register",
}

export enum AdminRoutes {
  Admin = "/admin/", //BaseRoute
  Administrators = "/admin/administrators",
  Administrator = "/admin/administrators/:adminId",
  AdministratorProfile = "/admin/administrator-profile/:adminId",
  CreateAdministrator = "/admin/administrators/create",
  EditAdministrator = "/admin/administrators/:adminId/edit",
  Artists = "/admin/artists",
  CreateArtist = "/admin/artists/create",
  Artist = "/admin/artists/:artistId",
  EditArtist = "/admin/artists/:artistId/edit",
  CreateArtistDonor = "/admin/artists/:artistId/investor/create",
  EditArtistDonor = "/admin/artists/:artistId/investor/:investorId",
  Donors = "/admin/donors",
  EditDonor = "/admin/donors/:donorId/edit",
  EmailTemplates = "/admin/templates",
  EmailEdit = "/admin/templates/:templateId/edit",
  ServiceProviders = "/admin/service-providers",
  EditServiceProviders = "/admin/service-providers/:supplierId",
  CreateServiceProviders = "/admin/service-providers/create",
}

export const adminRoute = {
  Administrators: () => AdminRoutes.Administrators,
  AdministratorProfile: (adminId: string) =>
    replaceParams(AdminRoutes.AdministratorProfile, { adminId }),
  Administrator: (adminId: string) =>
    replaceParams(AdminRoutes.Administrator, { adminId }),
  EditAdministrator: (adminId: string) =>
    replaceParams(AdminRoutes.EditAdministrator, { adminId }),
  CreateAdministrator: () => AdminRoutes.CreateAdministrator,
  Donors: () => AdminRoutes.Donors,
  EditDonor: (donorId: string) =>
    replaceParams(AdminRoutes.EditDonor, { donorId }),
  Artists: () => AdminRoutes.Artists,
  Artist: (artistId: string) => replaceParams(AdminRoutes.Artist, { artistId }),
  EditArtist: (artistId: string) => {
    return replaceParams(AdminRoutes.EditArtist, { artistId });
  },
  CreateArtistDonor: (artistId: string) =>
    replaceParams(AdminRoutes.CreateArtistDonor, { artistId }),
  EditArtistDonor: (artistId: string, investorId: string) =>
    replaceParams(AdminRoutes.EditArtistDonor, { artistId, investorId }),
  CreateArtist: () => AdminRoutes.CreateArtist,
  EmailTemplates: () => AdminRoutes.EmailTemplates,
  EmailEdit: (templateId: string) =>
    replaceParams(AdminRoutes.EmailEdit, { templateId }),
  ServiceProviders: () => AdminRoutes.ServiceProviders,
  EditServiceProviders: (supplierId: string) =>
    replaceParams(AdminRoutes.EditServiceProviders, { supplierId }),
  CreateServiceProviders: () => AdminRoutes.CreateServiceProviders,
};

export enum ArtistRoutes {
  Artist = "/artist/", //BaseRoute
  profile = "/artist/profile",
  EditArtist = "/artist/:artistId/edit",
  CreateArtistDonor = "/artist/:artistId/edit/investor/create",
  EditArtistDonor = "/artist/:artistId/edit/investor/:investorId",
}

export const artistRoute = {
  profile: (artistId: string) =>
    replaceParams(ArtistRoutes.profile, { artistId }),
  EditArtist: (artistId: string) =>
    replaceParams(ArtistRoutes.EditArtist, { artistId }),
  CreateArtistDonor: (artistId: string) =>
    replaceParams(ArtistRoutes.CreateArtistDonor, { artistId }),
  EditArtistDonor: (artistId: string, investorId: string) =>
    replaceParams(ArtistRoutes.EditArtistDonor, { artistId, investorId }),
};

export const sharedRoute = {
  Login: () => SharedRoutes.Login,
  Register: () => SharedRoutes.Register,
  DonorRegister: () => SharedRoutes.DonorRegister,
  ServiceProviderRegister: () => SharedRoutes.ServiceProviderRegister,
  ForgotPassword: () => SharedRoutes.ForgotPassword,
};

const replaceParams = (pathname: string, params: Record<string, string>) =>
  pathname.replace(/:\w+/g, (match) => {
    const param = match.substring(1);

    return params[param] || match;
  });

export const routes = createBrowserRouter([
  {
    // path: "/login",
    index: true,
    element: <Login />,
  },
  {
    path: SharedRoutes.Login,
    index: true,
    element: <Login />,
  },
  {
    path: SharedRoutes.ForgotPassword,
    index: true,
    element: <ForgotPassword />,
  },
  {
    path: SharedRoutes.Register,
    element: <ArtistLayout />,
    children: [
      {
        index: true,
        element: <CreateArtist />,
      },
    ],
  },
  {
    path: SharedRoutes.Otp,
    element: <Otp />,
  },
  {
    path: SharedRoutes.InitialPassword,
    element: <SetupPassword />,
  },
  {
    path: SharedRoutes.ArtistApprove,
    element: (
      <ProtectedRoute role={["Admin", "SuperAdmin"]}>
        <AdminLayout />
      </ProtectedRoute>
    ),
    children: [{ index: true, element: <Artist /> }],
  },
  {
    path: SharedRoutes.DonorRegister,
    element: <SharedLayout />,
    children: [{ index: true, element: <CreateDonor /> }],
  },
  {
    path: SharedRoutes.ServiceProviderRegister,
    element: <SharedLayout />,
    children: [{ index: true, element: <SupplierRegister /> }],
  },

  // // ================================
  // // ============ ADMINS ============
  // // ================================

  {
    path: AdminRoutes.Admin,
    element: (
      <ProtectedRoute role={["Admin"]}>
        <AdminLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute role={["SuperAdmin"]}>
            <Administrators />
          </ProtectedRoute>
        ),
      },
      {
        path: AdminRoutes.Administrators,
        element: (
          <ProtectedRoute role={["SuperAdmin"]}>
            <Administrators />
          </ProtectedRoute>
        ),
      },
      {
        path: AdminRoutes.EditAdministrator,
        element: (
          <ProtectedRoute role={["SuperAdmin"]}>
            <EditAdministrator />
          </ProtectedRoute>
        ),
      },
      {
        path: AdminRoutes.CreateAdministrator,
        element: (
          <ProtectedRoute role={["SuperAdmin"]}>
            <CreateAdministrator />
          </ProtectedRoute>
        ),
      },
      {
        path: AdminRoutes.Administrator,
        element: <Administrator />,
      },
      {
        path: AdminRoutes.AdministratorProfile,
        element: <Administrator />,
      },
      {
        path: AdminRoutes.Artists,
        element: <Artists />,
      },
      {
        path: AdminRoutes.Artist,
        element: <Artist />,
      },
      {
        path: AdminRoutes.CreateArtist,
        element: <CreateArtist />,
      },
      {
        path: AdminRoutes.EditArtist,
        element: <EditArtist />,
      },
      {
        path: AdminRoutes.Donors,
        element: <Donors />,
      },
      {
        path: AdminRoutes.EditDonor,
        element: <EditDonor />,
      },
      {
        path: AdminRoutes.CreateArtistDonor,
        element: <CreateArtistDonor />,
      },
      {
        path: AdminRoutes.EditArtistDonor,
        element: <EditArtistDonor />,
      },
      {
        path: AdminRoutes.EmailTemplates,
        element: <Emails />,
      },
      {
        path: AdminRoutes.EmailEdit,
        element: <EmailBuilder />,
      },
      {
        path: AdminRoutes.ServiceProviders,
        element: <Suppliers />,
      },

      {
        path: AdminRoutes.CreateServiceProviders,
        element: <SupplierRegister />,
      },
      {
        path: AdminRoutes.EditServiceProviders,
        element: <SupplierEdit />,
      },
    ],
  },

  // // ================================
  // // ============ ARTISTS ============
  // // ================================

  {
    path: ArtistRoutes.Artist,
    element: (
      <ProtectedRoute role={["Artist"]}>
        <ArtistLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <ArtistProfile />,
      },
      {
        path: ArtistRoutes.profile,
        element: <ArtistProfile />,
      },
      {
        path: ArtistRoutes.EditArtist,
        element: <EditArtist />,
      },
      {
        path: ArtistRoutes.CreateArtistDonor,
        element: <CreateArtistDonor />,
      },
      {
        path: ArtistRoutes.EditArtistDonor,
        element: <EditArtistDonor />,
      },
      {
        path: ArtistRoutes.EditArtistDonor,
        element: <EditArtistDonor />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: SharedRoutes.NotAllowed,
    element: <NotFound />,
  },
]);
