export const Instagram = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41699 4.24984C1.41699 2.68503 2.68552 1.4165 4.25033 1.4165H12.7503C14.3151 1.4165 15.5837 2.68503 15.5837 4.24984V12.7498C15.5837 14.3146 14.3151 15.5832 12.7503 15.5832H4.25033C2.68552 15.5832 1.41699 14.3146 1.41699 12.7498V4.24984ZM4.25033 2.83317C3.46792 2.83317 2.83366 3.46743 2.83366 4.24984V12.7498C2.83366 13.5323 3.46792 14.1665 4.25033 14.1665H12.7503C13.5328 14.1665 14.167 13.5323 14.167 12.7498V4.24984C14.167 3.46743 13.5328 2.83317 12.7503 2.83317H4.25033ZM8.50033 6.37484C7.32669 6.37484 6.37533 7.3262 6.37533 8.49984C6.37533 9.67347 7.32669 10.6248 8.50033 10.6248C9.67396 10.6248 10.6253 9.67347 10.6253 8.49984C10.6253 7.3262 9.67396 6.37484 8.50033 6.37484ZM4.95866 8.49984C4.95866 6.54383 6.54432 4.95817 8.50033 4.95817C10.4563 4.95817 12.042 6.54383 12.042 8.49984C12.042 10.4558 10.4563 12.0415 8.50033 12.0415C6.54432 12.0415 4.95866 10.4558 4.95866 8.49984ZM12.3962 5.6665C12.9829 5.6665 13.4587 5.19081 13.4587 4.604C13.4587 4.0172 12.9829 3.5415 12.3962 3.5415C11.8094 3.5415 11.3337 4.0172 11.3337 4.604C11.3337 5.19081 11.8094 5.6665 12.3962 5.6665Z"
        fill="#4c4c4c"
      />
    </svg>
  );
};
