import React from "react";
import { useNavigate } from "react-router-dom";
import "./notFound.scss";

export default function NotFound() {
  return (
    <div className="not-found">
      <div className="container text-align-center">
        <img
          src="/Arts-Capital-logo.png"
          height={81}
          width={230}
          alt="logo"
          className="mt-2 mb-2"
        ></img>
        <div className="error-code">404</div>
        <div className="error-message">Page Not Found</div>
        <button
          className="button-green mt-3"
          onClick={() =>
            window.location.replace("http://www.artscapital.co.za/thank-you")
          }
        >
          Home
        </button>
      </div>
    </div>
  );
}
