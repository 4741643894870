//TODO George: find a better way to check if error on forms (perhaps callback to parents)
// Current way to cehck validity:  document.querySelectorAll('.is-invalid').length === 0
function resolve(cur: any, ns: any) {
  var undef;

  ns = ns.split(".");

  while (cur && ns[0]) cur = cur[ns.shift()] || undef;

  return cur;
}

export const Validators = {
  isEmail: (val: any): boolean => {
    //const regexEmail = RegExp(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);
    const regexEmail = RegExp(
      /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])+\.+[A-Za-z]+$/
    );

    return regexEmail.test(val);
  },
  isMobile: (val: any): boolean => {
    const numbers = [
      RegExp(/^\+\d{11}$/),
      RegExp(/^\+\d{2}\s\d{2}\s\d{3}\s\d{4}$/),
      RegExp(/^\d{10}$/),
      RegExp(/^\d{3}\s\d{3}\s\d{4}$/),
      RegExp(/^\(\+\d{2}\)\s\d{2}\s\d{3}\s\d{4}$/),
    ];

    return numbers.some((c) => c.test(val));
  },
  isRequired: (val: any): boolean => {
    return !!val;
  },
  isID: (val: any): boolean => {
    if (!(!!val) || val === null || val.length > 13) {
      return false;
    }
    let tempTotal = 0;
    let checkSum = 0;
    let multiplier = 1;
    for (let i = 0; i < 13; i += 1) {
      tempTotal = parseInt(val.charAt(i), 10) * multiplier;
      if (tempTotal > 9) {
        tempTotal =
          parseInt(tempTotal.toString().charAt(0), 10) +
          parseInt(tempTotal.toString().charAt(1), 10);
      }
      checkSum += tempTotal;
      multiplier = multiplier % 2 === 0 ? 1 : 2;
    }
    if (checkSum % 10 !== 0) {
      return false;
    }
    return true;
  },
  hasLength: (val: any): boolean => {
    return val && val.length > 0;
  },

  hasCapital: (val: any): boolean => {
    const regX = RegExp(/(?=.*[A-Z])/);
    return regX.test(val);
  },
  hasEightChars: (val: any): boolean => {
    const regX = RegExp(/(?=.{8,})/);
    return regX.test(val);
  },
  hasDigit: (val: any): boolean => {
    const regX = RegExp(/(?=.*[0-9])/);
    return regX.test(val);
  },
  hasSpecial: (val: any): boolean => {
    const regX = RegExp(/([^A-Za-z0-9])/);
    return regX.test(val);
  },
  hasLower: (val: any): boolean => {
    const regX = RegExp(/(?=.*[a-z])/);
    return regX.test(val);
  },
  isMinutes: (val: any): boolean => {
    try {
      return Number(val) >= 0 && Number(val) < 60;
    } catch (error) {
      return false;
    }
  },
  isPositive: (val: any): boolean => {
    try {
      return Number(val) >= 0;
    } catch (error) {
      return false;
    }
  },
  validDate: (val: any): boolean => {
    try {
      if (val.length === "dd-mm-yyyy".length) {
        const datePattern = RegExp(/^(\d{2})-(\d{2})-(\d{4})$/);
        return datePattern.test(val);
      }
      return false;
    } catch {
      return false;
    }
  },
  message: {
    isEmail: "Please enter a valid email",
    isMobile: "Please enter a valid contact number",
    isRequired: "Please enter a value",
    isID: "Please enter a valid ID",
    hasLength: "Please select a value",
    hasCapital: "Please enter at least one capital letter",
    hasEightChars: "Please enter at least 8 characters",
    hasDigit: "Please enter at least one number",
    hasSpecial: "Please enter at least one special character",
    hasLower: "Please enter at least one lowercase letter",
    isPositive: "Please enter a value 0 or larger",
    isMinutes: "Please enter a value from 0 - 59",
    validDate: "Please enter a valid Date",
  },

  validateState: (state: any, validations: any) => {
    let valid = true;
    Object.keys(validations)?.forEach((key) => {
      validations[key] = validations[key].map((validation: any) => {
        try {
          let value = validation.check(resolve(state, key));
          validation.isValid = value;
          if (!value) valid = false;
        } catch {}
        return validation;
      });
    });

    return valid;
  },
};
