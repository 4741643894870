import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { EmailTemplateTypes } from "../../interface/emailTemplates";

interface EmailTemplateTypesState {
  templateTypes: EmailTemplateTypes[];
}

const defualtState: EmailTemplateTypesState = {
  templateTypes: [],
};

export const templateTypesSlice = createSlice({
  name: "templateTypes",
  initialState: defualtState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(emailTemplateTypesApiRequest.fulfilled, (state, action) => {
      state.templateTypes = action.payload;
    });
  },
});

export const emailTemplateTypesApiRequest = createAsyncThunk(
  "email-templates/types",
  async (apiEndpoint, thunkAPI) => {
    try {
      // @ts-ignore
      const token = thunkAPI.getState()?.auth?.accessToken ?? ""; // Assuming you store the token in the 'auth' slice
      const response = await axios.get(
        `https://api.prod.artscapital.co.za/api/email-templates/types`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (axiosError) {
      return [];
    }
  }
);

export default templateTypesSlice.reducer;

export const templateTypesState = createSelector(
  (state: any) => state.templateType,
  (templateType) => templateType
);
