export const CameraSvg = () => {
  return (
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 23.5787V10.092C2 8.6023 3.20764 7.39467 4.69733 7.39467H5.37167C6.22067 7.39467 7.02013 6.99494 7.52953 6.31573L10.5236 2.32368C10.6764 2.11992 10.9162 2 11.1709 2H19.8024C20.0572 2 20.297 2.11992 20.4498 2.32368L23.4438 6.31573C23.9532 6.99494 24.7527 7.39467 25.6017 7.39467H26.276C27.7657 7.39467 28.9733 8.6023 28.9733 10.092V23.5787C28.9733 25.0684 27.7657 26.276 26.276 26.276H4.69733C3.20764 26.276 2 25.0684 2 23.5787Z"
        stroke="#4c4c4c"
        strokeWidth="2.68967"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4865 20.8816C18.4658 20.8816 20.8811 18.4663 20.8811 15.487C20.8811 12.5076 18.4658 10.0923 15.4865 10.0923C12.5071 10.0923 10.0918 12.5076 10.0918 15.487C10.0918 18.4663 12.5071 20.8816 15.4865 20.8816Z"
        stroke="#4c4c4c"
        strokeWidth="2.68967"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
