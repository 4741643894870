import React, { FormEvent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ContactMethods from "../../components/contactMethods/contactMethods";
import ImageUpload from "../../components/imageUpload/imageUpload";
import TextInput from "../../components/textInput/textInput";
import { selectCurrentUser } from "../../features/auth/authSlice";
import {
  useGetAdminQuery,
  useSuspendAdminMutation,
  useUpdateAdminMutation,
} from "../../features/users/usersApiSlice";
import {
  AccountDetailRequestInterface,
  NotificationMethod,
} from "../../interface/account";
import { Validators } from "../../validators";
import ReasonModal from "../../components/reasonModal/reasonModal";
import Loader from "../../components/loader/loader";
import { adminRoute } from "../../routes";
const EditAdministrator = () => {
  const navigate = useNavigate();
  const { adminId } = useParams<{ adminId: any }>();
  const { roles, userId } = useSelector(selectCurrentUser);
  const [updateAdmin, { isLoading: submitting }] = useUpdateAdminMutation();
  const [suspendModalOpen, setSuspendModalOpen] = useState<boolean>(false);
  const [suspendAdmin] = useSuspendAdminMutation();

  const { data: userDetails, isLoading } = useGetAdminQuery(adminId, {
    skip: !adminId,
  });

  const [admin, setAdmin] = useState<AccountDetailRequestInterface>({
    name: "",
    surname: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    idNumber: "",
    profileImageUrl: null,
    email: "",
    mobile: "",
    artistTypes: null,
    instrumentTypes: null,
    facebookHandle: "",
    twitterHandle: "",
    instagramHandle: "",
    tikTokHandle: "",
    benefits: [],
    pensionFund: 0,
    medicalAid: 0,
    savings: 0,
    lifeCover: 0,
    funeralCover: 0,
    legalCover: 0,
    approved: false,
    suspended: false,
    suspendReason: "",
    notificationMethod: NotificationMethod[NotificationMethod.Email],
  });

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    idNumber: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isID,
        isValid: true,
      },
    ],
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const handleContactChange = (key: string, value: string) => {
    setAdmin((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAdmin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useMemo(() => {
    if (userDetails) {
      setAdmin(userDetails);
    }
  }, [userDetails]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(admin, validations);
    setValidations({ ...validations });

    if (valid) {
      updateAdmin(admin)
        .unwrap()
        .then((payload) => {
          navigate(adminRoute.Administrators());
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }
      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };

  const handleAdminSuspend = (status: boolean, reason: string) => {
    suspendAdmin({ status, systemUserId: adminId, reason })
      .unwrap()
      .then(() => {
        setSuspendModalOpen(false);
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  return (
    <>
      <h1 className="mb-2">Edit Profile</h1>
      {isLoading || !admin.systemUserId ? (
        <Loader />
      ) : (
        <>
          {suspendModalOpen && (
            <ReasonModal
              setOpenModal={() => setSuspendModalOpen(false)}
              title={`Suspend ${admin?.name}'s profile`}
              onSubmit={(value) => handleAdminSuspend(true, value)}
            />
          )}
          <ImageUpload
            entityId={userDetails.systemUserId}
            img={admin.profileImageUrl}
          />
          {adminId !== userId &&
            roles.includes("SuperAdmin") &&
            admin.suspended && (
              <button
                style={{ width: "100%", maxWidth: "180px" }}
                className="mt-1"
                onClick={() => handleAdminSuspend(false, "")}
              >
                Un-suspend
              </button>
            )}
          {adminId !== userId &&
            roles.includes("SuperAdmin") &&
            !admin.suspended && (
              <button
                style={{ width: "100%", maxWidth: "180px" }}
                className="error mt-1"
                onClick={() => setSuspendModalOpen(true)}
              >
                Suspend
              </button>
            )}
          <ContactMethods
            value={admin.notificationMethod}
            onChange={(value) =>
              handleContactChange("notificationMethod", value)
            }
          />
          <div>
            <p className="title">Personal Information</p>
            <div className="input-grid">
              <TextInput
                type="text"
                name="name"
                placeholder="Name"
                value={admin.name}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="surname"
                placeholder="Surname"
                value={admin.surname}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="string"
                name="mobile"
                placeholder="Contact number"
                value={admin.mobile}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="email"
                name="email"
                placeholder="Email address"
                value={admin.email}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="number"
                name="idNumber"
                placeholder="ID number"
                value={admin.idNumber}
                inputChanged={handleChange}
                validations={validations}
              />
            </div>
            <p className="title">Address Information</p>
            <div className="input-grid">
              <TextInput
                type="text"
                name="address1"
                placeholder="Street address"
                value={admin.address1}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address2"
                placeholder="Unit / Building no."
                value={admin.address2}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address3"
                placeholder="Suburb"
                value={admin.address3}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="text"
                name="address4"
                placeholder="Province"
                value={admin.address4}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="number"
                name="address5"
                placeholder="Postal Code"
                value={admin.address5}
                inputChanged={handleChange}
                validations={validations}
              />
            </div>
            {roles?.includes("SuperAdmin") ? (
              <label
                style={{ width: "fit-content" }}
                className="flex flex-row align-items-center p-0"
              >
                <input
                  type="checkbox"
                  className="brand-check"
                  checked={roles.includes("SuperAdmin") ? true : false}
                  onChange={() => {}}
                />
                Super user
              </label>
            ) : null}
            <div className="flex flex-row pl-0 mt-2">
              <button
                className="gray"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                Back
              </button>
              <button type="submit" onClick={handleSubmit}>
                {!submitting ? "Save" : <Loader />}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditAdministrator;
