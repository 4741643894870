export const LocationSvg = () => {
  return (
    <svg
      width="23"
      height="29"
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 11.9629C23 20.9073 11.5 28.574 11.5 28.574C11.5 28.574 0 20.9073 0 11.9629C4.54484e-08 8.9129 1.2116 5.98783 3.36827 3.83116C5.52494 1.67449 8.45001 0.462891 11.5 0.462891C14.55 0.462891 17.4751 1.67449 19.6317 3.83116C21.7884 5.98783 23 8.9129 23 11.9629Z"
        fill="#545350"
      />
      <path
        d="M11.4993 15.7961C13.6164 15.7961 15.3327 14.0798 15.3327 11.9627C15.3327 9.84564 13.6164 8.12939 11.4993 8.12939C9.38226 8.12939 7.66602 9.84564 7.66602 11.9627C7.66602 14.0798 9.38226 15.7961 11.4993 15.7961Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
