import React, { useEffect, useState } from "react";
import { ViewSvg } from "../svg/view";
import "./table.scss";

interface TableHeader {
  header: string;
  identifier: string;
  order: number;
}

interface Props {
  headers: TableHeader[];
  dataset: any[];
  redirectFn?: (data: any) => void;
}

const Table: React.FC<Props> = ({ headers, dataset, redirectFn }) => {
  const sortedHeaders = [...headers].sort((a, b) => a.order - b.order);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      {dataset.length === 0 && (
        <h3 className="mt-1 text-align-center">No data present</h3>
      )}
      <table className="dyna-t" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {sortedHeaders.map((header) => (
              <th key={header.identifier}>{header.header}</th>
            ))}
            {redirectFn && <th></th>}
          </tr>
        </thead>
        <tbody>
          {dataset.map((data, i) => (
            <tr key={i}>
              {sortedHeaders.map((header) => (
                <td key={header.identifier}>
                  {header.identifier === "status"
                    ? data.suspended
                      ? "Suspended"
                      : data.approved
                      ? "Approved"
                      : data.rejectReason
                      ? "Rejected"
                      : "Pending"
                    : data[header.identifier]}
                </td>
              ))}
              {redirectFn && (
                <td style={{ width: "25px" }}>
                  <div onClick={() => redirectFn(data)} className="pointer">
                    <ViewSvg />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {dataset.map((data, i) => (
        <div className="table-container card mb-1" key={`cont-${i}`}>
          {sortedHeaders.map((header) => (
            <div key={header.identifier} className={`${header.identifier}`}>
              {header.identifier === "status"
                ? data.suspended
                  ? "Suspended"
                  : data.approved
                  ? "Approved"
                  : data.rejectReason
                  ? "Rejected"
                  : "Pending"
                : data[header.identifier]}
            </div>
          ))}
          {redirectFn && (
            <div onClick={() => redirectFn(data)} className="pointer action">
              <ViewSvg />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Table;
