import { Editor } from "@tinymce/tinymce-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import "./emailBuilder.scss";
import {
  usePreviewTemplateMutation,
  useTemplateQuery,
  useUpdateTemplateMutation,
} from "../../../features/emailTemplates/emailTemplateApiSlice";
import {
  emailTemplateTypesApiRequest,
  templateTypesState,
} from "../../../features/emailTemplates/emailTemplateSlice";
import { useAppDispatch } from "../../../app/reduxHooks";
import { EmailTemplateTags } from "../../../interface/emailTemplates";
import EmailPreviewModal from "./emailPreviewModal";
import Loader from "../../../components/loader/loader";
import TextInput from "../../../components/textInput/textInput";
import { adminRoute } from "../../../routes";

const EmailBuilder = () => {
  const [ready, setReady] = useState(false);
  const { templateId } = useParams<{ templateId: any }>();
  const { data: template, isLoading } = useTemplateQuery(templateId);

  const [updateTemplate, { isLoading: submitting }] =
    useUpdateTemplateMutation();
  const [previewTemplate] = usePreviewTemplateMutation();

  const { templateTypes } = useSelector(templateTypesState);
  const dispatch = useAppDispatch();

  const [element, setElement] = useState("");
  const [emailType, setemailType] = useState("");
  const [subject, setSubject] = useState("");
  const [previewContent, setPreviewContent] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [value, setValue] = useState("");
  const [tags, setTags] = useState<EmailTemplateTags[]>([]);

  const editorRef = useRef<Editor | null>(null);

  const navigate = useNavigate();

  const insertText = (value: string) => {
    if (element === "subject") {
      setSubject(subject + value);
    } else {
      // @ts-ignore
      editorRef.current.execCommand("mceInsertContent", false, value);
    }
  };

  // @ts-ignore
  const handleOnInit = (evt, editor) => {
    editorRef.current = editor;
    setReady(true);
  };

  const handleSubjectChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSubject(e.target.value);
  };

  const handlePreview = () => {
    if (editorRef.current && template) {
      // @ts-ignore
      const content = editorRef.current.getContent();
      previewTemplate(content.toString())
        .unwrap()
        .then((res) => {
          setPreviewContent(res.message);
          setPreviewOpen(true);
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    }
  };

  const handleSubmit = () => {
    if (template) {
      const content =
        template.notificationMethod === "SMS"
          ? value
          : // @ts-ignore
            editorRef?.current?.getContent() ?? value;
      updateTemplate({
        notificationBody: content,
        notificationSubject: subject,
        notificationType: template.notificationType,
        templateId: template.notificationTemplateId,
      })
        .unwrap()
        .then(() => {
          toast.success("Template uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnHover: false,
            pauseOnFocusLoss: false,
          });
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    }
  };

  useEffect(() => {
    if (templateTypes.length === 0) {
      dispatch(emailTemplateTypesApiRequest());
    }

    return () => {};
  }, [templateTypes]);

  useEffect(() => {
    setemailType(template?.notificationType ?? "");
    setSubject(template?.notificationSubject ?? "");
    setValue(template?.notificationBody ?? "");
    if (templateTypes) {
      const tags = templateTypes?.find(
        (c: any) => c.notificationType === template?.notificationType
      )?.notificationTags;
      setTags(tags ?? []);
    }
    if (template && template.notificationMethod === "SMS") {
      setReady(true);
    }

    return () => {};
  }, [template, templateTypes]);

  return (
    <>
      {previewOpen && (
        <EmailPreviewModal
          content={previewContent}
          title={emailType}
          setOpenModal={() => setPreviewOpen(false)}
        />
      )}
      <div className={`${ready || isLoading ? "d-none" : ""}`}>
        <Loader />
      </div>
      {!isLoading && (
        <div className={`${!ready && !isLoading ? "d-none" : ""}`}>
          <div
            className="emailbuilder-grid"
            onClick={() => setElement("editor")}
          >
            <div className="flex flex-column pr-0 pl-0">
              <h3 className="mb-0">Subject</h3>
              <div onClick={() => setElement("subject")}>
                <TextInput
                  type="text"
                  name="subject"
                  value={subject}
                  inputChanged={handleSubjectChanged}
                  placeholder="Subject"
                />
              </div>
              <div className="flex justify-content-space-between align-items-center mb-0 p-0">
                <h3 className="mb-0">Body</h3>
                {template?.notificationMethod !== "SMS" && (
                  <button onClick={handlePreview}>Preview</button>
                )}
              </div>
              {!isLoading && template?.notificationMethod !== "SMS" && (
                <Editor
                  initialValue={value}
                  apiKey="6sv1enwij0ql562hwz6swykf1ocdb3gwxwsb2efrr37s772i"
                  onInit={handleOnInit}
                  init={{
                    plugins: "emoticons lists help",
                    toolbar:
                      "undo redo | styles | bold italic forecolor | emoticons | align | mergetags inserttemplate | spellcheckdialog a11ycheck | code removeformat",
                    toolbar_sticky: true,
                    menubar: false,
                    height: 500,
                    editable_class: "tiny-editable",
                    elementpath: false,
                    visual: false,
                    link_target_list: false,
                    object_resizing: false,
                    formats: {
                      h1: {
                        block: "h1",
                        styles: {
                          fontSize: "32px",
                          fontFamily: "Times New Roman",
                        },
                      },
                      h2: {
                        block: "h2",
                        styles: {
                          fontSize: "28px",
                          fontFamily: "Times New Roman",
                        },
                      },
                      largetext: {
                        block: "p",
                        styles: { fontSize: "18px", fontFamily: "sans-serif" },
                      },
                      p: {
                        block: "p",
                        styles: { fontSize: "16px", fontFamily: "sans-serif" },
                      },
                      calltoaction: {
                        block: "a",
                        styles: {
                          fontSize: "16px",
                          color: "#a8b147",
                          borderRadius: "4px",
                          textDecoration: "none",
                          display: "inline-block",
                        },
                      },
                    },
                    style_formats: [
                      { title: "Paragraph", format: "p" },
                      { title: "Heading 1", format: "h1" },
                      { title: "Heading 2", format: "h2" },
                      { title: "Large text", format: "largetext" },
                      { title: "Button styles" },
                      { title: "Call-to-action", format: "calltoaction" },
                    ],
                    images_file_types: "jpeg,jpg,png,gif",
                  }}
                />
              )}
              {!isLoading && template?.notificationMethod === "SMS" && (
                <textarea
                  className="brand-input"
                  placeholder=""
                  value={value}
                  rows={15}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
            </div>
            <div className="flex flex-column align-items-center pr-0 g-0">
              <h3 className="mb-1">Tags</h3>
              {tags.length === 0 && <p>No tags available</p>}
              {tags.map((c) => {
                return (
                  <button
                    key={c.tag}
                    onClick={() => {
                      insertText(c.tag);
                    }}
                    className="insert-mce-button"
                    style={{ cursor: "pointer" }}
                  >
                    {c.description}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="flex flex-row p-0 mt-1">
            <button
              className="gray"
              onClick={(e) => {
                e.preventDefault();
                navigate(adminRoute.EmailTemplates());
              }}
            >
              Back
            </button>
            <button onClick={handleSubmit}>
              {!submitting ? "Update" : <Loader />}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailBuilder;
