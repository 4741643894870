import { useState } from "react";
import "./flippy.scss";

interface FlippyProps {
  title: string;
  subTitle?: string;
  description?: string;
  img: string;
  padded?: boolean;
  value: string;
  children: any;
}
export default function Flippy({
  title,
  subTitle,
  description,
  img,
  padded = false,
  value,
  children,
}: FlippyProps) {
  const [flipped, setFlipped] = useState(false);

  return (
    <>
      <div
        className={`flippy-card ${flipped ? "flipped" : ""} ${
          !!description ? "has-description" : ""
        }`}
      >
        <div className="flippy-content">
          <div
            className={`flippy-front ${padded ? "padded" : ""}`}
            onClick={() => setFlipped(true)}
          >
            <img
              src={img}
              alt={title}
              width={100}
              height={150}
              className="mt-1"
            />
            <p className={`flippy-title ${!!description ? "mt-1" : "mt-2"}`}>
              {title}
            </p>
            <p className="flippy-sub-title">{subTitle}</p>
            <button className="flippy-button" onClick={() => setFlipped(true)}>
              R{value}
            </button>
          </div>
          <div className={`flippy-back ${padded ? "padded" : ""}`}>
            {children}
            <button className="flippy-button" onClick={() => setFlipped(false)}>
              Go back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
