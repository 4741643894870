import React, { useState, useEffect, useRef } from "react";
import "./text-input.scss";
import { Validators } from "../../validators";
import { ViewSvg } from "../svg/view";

type validations = {
  isValid: boolean;
  check: (val: any) => boolean;
};

type TextInputProps = {
  type: string;
  name: string;
  placeholder: string;
  inputChanged: any;
  value: any;
  validations?: { [key: string]: validations[] };
  disabled?: boolean;
};
const TextInput = ({
  type,
  name,
  value,
  placeholder,
  inputChanged,
  validations,
  disabled,
}: TextInputProps) => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const isMounted = useRef(false);
  const [passwordType, setPasswordType] = useState<string>(type);

  const handleChange = (e: any) => {
    isMounted.current = true;
    inputChanged(e);
    validate(e.target.value);
  };

  useEffect(() => {
    if (isMounted.current) {
      validate();
      getFirstError();
    }
  }, [value, validations]);

  useEffect(() => {
    getFirstError();
  }, [validations]);

  const validate = (val?: any) => {
    if (!!validations) {
      validations[name]?.forEach((c) => {
        c.isValid = c.check(!!val ? val : value);
      });

      const firstError = validations[name]?.filter((c) => !c.isValid)[0];
      setMessage("");
      if (firstError)
        // @ts-ignore
        setMessage(Validators.message[firstError.check.name] ?? "Error");

      setError(validations[name]?.some((c) => !c.isValid));
    }
  };

  const getFirstError = () => {
    if (!!validations) {
      const firstError = validations[name]?.filter((c) => !c.isValid)[0];
      setMessage("");
      if (firstError)
        // @ts-ignore
        setMessage(Validators.message[firstError.check.name] ?? "Error");
    }
  };

  const handleOnWheel = (e: any) => {
    e.target.blur();
    return false;
  };

  const togglePassword = (e: any) => {
    e.preventDefault();
    if (type !== "password") return;
    setPasswordType(passwordType === "text" ? "password" : "text");
  };

  if (type === "password") {
    return (
      <div style={{ width: "100%" }}>
        <p className="label m-0">{placeholder}</p>
        <div className="password-wrapper">
          <input
            type={passwordType}
            value={value ?? ""}
            onChange={handleChange}
            onPaste={handleChange}
            onBlur={handleChange}
            className={`brand-input form-control ${
              !!validations && validations[name]?.some((c) => !c.isValid)
                ? "is-invalid"
                : ""
            }`}
            onWheel={handleOnWheel}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
          ></input>

          <div onClick={togglePassword} className="pointer btn-password">
            <ViewSvg />
          </div>
        </div>
        {!!validations && validations[name]?.some((c) => !c.isValid) && (
          <div className="invalid-feedback">{message}</div>
        )}
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <p className="label m-0">{placeholder}</p>
      <input
        type={type}
        value={value ?? ""}
        onChange={handleChange}
        onPaste={handleChange}
        onBlur={handleChange}
        className={`brand-input form-control ${
          !!validations && validations[name]?.some((c) => !c.isValid)
            ? "is-invalid"
            : ""
        }`}
        onWheel={handleOnWheel}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
      ></input>
      {!!validations && validations[name]?.some((c) => !c.isValid) && (
        <div className="invalid-feedback">{message}</div>
      )}
    </div>
  );
};

export default TextInput;
