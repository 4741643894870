import React from "react";
import { useState } from "react";
import {
  Link,
  NavLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { AdminRoutes, adminRoute } from "../../routes";

type navbarProps = {
  hideMenu: boolean;
};
const Navbar = ({ hideMenu }: navbarProps) => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const userIdState = useSelector((state: any) => state.auth.userId);
  const authState = useSelector(selectCurrentUser);
  const toggleMenu = (e: any) => {
    if (menu) {
      document.body.style.overflow = "unset";
    }
    if (!menu) {
      document.body.style.overflow = "hidden";
    }

    setMenu(!menu);
  };

  const location = useLocation();
  const isPathActive = (pathToMatch: string) => {
    const path = location.pathname;
    //@ts-ignore
    return !!matchPath(path, pathToMatch);
  };

  const handleLogout = async () => {
    await dispatch(logOut());
    window.location.replace("http://www.artscapital.co.za");
  };

  return (
    <header className="header">
      <Link className="navbar-brand" to="/">
        <img
          src="/Arts-Capital-logo.png"
          height={60}
          width={180}
          alt="logo"
        ></img>
      </Link>
      <input
        className="menu-btn"
        type="checkbox"
        id="menu-btn"
        readOnly
        checked={menu}
      />
      <label className="menu-icon" htmlFor="menu-btn" onClick={toggleMenu}>
        <span className="navicon"></span>
      </label>
      <ul className="menu">
        {!hideMenu && (
          <>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  isPathActive(AdminRoutes.AdministratorProfile) ? "active" : ""
                }`}
                to={adminRoute.AdministratorProfile(userIdState)}
                onClick={toggleMenu}
              >
                My Profile
              </NavLink>
            </li>
            {authState.roles.includes("SuperAdmin") && (
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${
                    isPathActive(AdminRoutes.Administrators) ? "active" : ""
                  }`}
                  to={adminRoute.Administrators()}
                  onClick={toggleMenu}
                >
                  Administrators
                </NavLink>
              </li>
            )}
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  isPathActive(AdminRoutes.Artists) ? "active" : ""
                }`}
                to={adminRoute.Artists()}
                onClick={toggleMenu}
              >
                Artists
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  isPathActive(AdminRoutes.Donors) ? "active" : ""
                }`}
                to={adminRoute.Donors()}
                onClick={toggleMenu}
              >
                Donor Investors
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  isPathActive(AdminRoutes.ServiceProviders) ? "active" : ""
                }`}
                to={adminRoute.ServiceProviders()}
                onClick={toggleMenu}
              >
                Service providers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${
                  isPathActive(AdminRoutes.EmailTemplates) ? "active" : ""
                }`}
                to={adminRoute.EmailTemplates()}
                onClick={toggleMenu}
              >
                Notification Templates
              </NavLink>
            </li>
          </>
        )}
        {userIdState && (
          <li>
            <a className="logout" onClick={handleLogout}>
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5"
                    stroke="#e13c3c"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </svg>
              &nbsp; Logout
            </a>
          </li>
        )}
      </ul>
    </header>
  );
};
export default Navbar;
