export const EditSvg = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      className="edit-svg"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5124 17.7305V22.3493C23.5124 22.9618 23.2518 23.5492 22.7877 23.9823C22.3237 24.4154 21.6943 24.6587 21.0381 24.6587H3.71753C3.06129 24.6587 2.43192 24.4154 1.96789 23.9823C1.50386 23.5492 1.24316 22.9618 1.24316 22.3493V6.18343C1.24316 5.57094 1.50386 4.98353 1.96789 4.55043C2.43192 4.11734 3.06129 3.87402 3.71753 3.87402H8.66626"
        stroke="#A2A2A2"
        strokeWidth="2.30941"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2324 17.4997L25.9856 6.41458L20.7894 1.56482L9.03619 12.5345L8.66504 17.7307L14.2324 17.4997Z"
        stroke="#A2A2A2"
        strokeWidth="2.30941"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
