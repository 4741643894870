import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ImageUpload from "../../components/imageUpload/imageUpload";
import { EditSvg } from "../../components/svg/edit";
import { Identity } from "../../components/svg/identity";
import { Mail } from "../../components/svg/mail";
import { Phone } from "../../components/svg/phone";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useGetAdminQuery } from "../../features/users/usersApiSlice";
import { adminRoute } from "../../routes";
import Loader from "../../components/loader/loader";
const Administrator = () => {
  const { adminId } = useParams<{ adminId: any }>();
  const { roles, userId } = useSelector(selectCurrentUser);
  const { data: userDetails, isLoading } = useGetAdminQuery(adminId, {
    skip: !adminId,
  });

  return (
    <>
      <h1 className="mb-2">Profile</h1>
      {isLoading || !userDetails.systemUserId ? (
        <Loader />
      ) : (
        <div className="profile-grid">
          <div className="profile">
            <div className="card p-0 flex flex-column">
              <ImageUpload
                entityId={adminId}
                img={userDetails.profileImageUrl}
                fullWidth
                float
              />
              <div className="pl-1 pb-1 pr-1">
                <div
                  className={`status-label status-${
                    !!userDetails?.rejectReason
                      ? "rejected"
                      : userDetails?.suspended
                      ? "rejected"
                      : userDetails?.approved
                      ? "active"
                      : "inactive"
                  }`}
                >
                  <p>
                    {!!userDetails?.rejectReason
                      ? "Rejected"
                      : userDetails?.suspended
                      ? "Suspended"
                      : userDetails?.approved
                      ? "Active"
                      : "Inactive"}
                  </p>
                  <span className="tooltiptext">
                    {userDetails?.suspended
                      ? userDetails.suspendReason
                      : userDetails?.rejectReason}
                  </span>
                </div>
                <h2 className="mt-0">
                  {userDetails?.name} {userDetails?.surname}
                </h2>
              </div>
            </div>
          </div>
          <div className="profile-details">
            <div className="card">
              <div className="flex flex-row justify-content-space-between align-items-center p-0">
                <h2>About</h2>
                {(roles.includes("SuperAdmin") || adminId === userId) && (
                  <a
                    className="edit-btn"
                    href={adminRoute.EditAdministrator(adminId)}
                  >
                    <EditSvg />
                  </a>
                )}
              </div>
              <h3 className="mt-1">PERSONAL INFORMATION</h3>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <div className="icon-gb">
                    <Identity />
                  </div>
                  <p>ID number:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.idNumber ? userDetails.idNumber : "N/A"}
                </p>
              </div>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <div className="icon-gb">
                    <Mail />
                  </div>
                  <p>Email address:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.email ? userDetails.email : "N/A"}
                </p>
              </div>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <div className="icon-gb">
                    <Phone />
                  </div>
                  <p>Contact number:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.mobile ? userDetails.mobile : "N/A"}
                </p>
              </div>
              <h3 className="mt-1">ADDRESS INFORMATION</h3>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <p>Street address:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.address1 ? userDetails.address1 : "N/A"}
                </p>
              </div>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <p>Unit / Building no.:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.address2 ? userDetails.address2 : "N/A"}
                </p>
              </div>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <p>Suburb:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.address3 ? userDetails.address3 : "N/A"}
                </p>
              </div>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <p>Province:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.address4 ? userDetails.address4 : "N/A"}
                </p>
              </div>
              <div className="input-grid m-0">
                <div className="flex flex-row justify-content-start p-0 align-items-center">
                  <p>Postal Code:</p>
                </div>
                <p className="color-gray">
                  {userDetails?.address5 ? userDetails.address5 : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Administrator;
