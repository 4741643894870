import { apiSlice } from "../../app/api/apiSlice";
import { EmailTemplates } from "../../interface/emailTemplates";

export const emailTemplateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GETS
    templates: builder.query<EmailTemplates[], any>({
      query: (payload: any) => ({
        url: "email-templates",
      }),
      providesTags: ["EmailTemplates"],
    }),
    template: builder.query<EmailTemplates, number>({
      query: (templateId: any) => ({
        url: "email-templates/" + templateId,
      }),
    }),
    // PUTS
    updateTemplate: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "email-templates/" + payload.templateId,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["EmailTemplates"],
    }),
    // POSTS
    previewTemplate: builder.mutation<any, string>({
      query: (payload: string) => ({
        url: "email-templates/preview",
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json", // Set the content type to application/json
        },
      }),
    }),
  }),
});

export const {
  useTemplateQuery,
  useTemplatesQuery,
  useUpdateTemplateMutation,
  usePreviewTemplateMutation,
} = emailTemplateApiSlice;
