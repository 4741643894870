import { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import Table from "../../components/dynamicTable/table";
import SearchBar from "../../components/search/search";
import { useGetDonorsQuery } from "../../features/investors/investorsApiSlice";
import { Investor } from "../../interface/investor";
import { PageSearchResponse } from "../../interface/queries";
import { adminRoute } from "../../routes";
import Loader from "../../components/loader/loader";
import { useGetSuppliersQuery } from "../../features/suppliers/suppliersApiSlice";
import { AddSvg } from "../../components/svg/add";
import { Supplier } from "../../interface/supplier";
const Suppliers = () => {
  const [pageCount, setPageCount] = useState<any>(0);
  const [term, setTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();

  const { data: suppliers, isLoading } = useGetSuppliersQuery({
    pageSize,
    pageNumber,
    term,
  });

  const convertNumToTime = (number: number) => {
    // Check sign of given number
    var sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + "";

    // Add padding if need
    if (minute.length < 2) {
      minute = "0" + minute;
    }

    return {
      hours: hour.toString(),
      minutes: minute,
    };
  };

  const mappedSuppliers: PageSearchResponse = useMemo(() => {
    if (suppliers) {
      setPageCount(suppliers.totalNumberOfPages);

      const mapped = suppliers?.collection?.map((c: Supplier) => {
        const time = convertNumToTime(c.hoursAvailable);
        const remaining = convertNumToTime(
          Number(c.hoursAvailable ?? 0) - Number(c.hoursConsumed ?? 0)
        );

        return {
          ...c,
          fullName: `${c.name} ${c.surname}`,
          timeAvailable: `${time.hours}h ${
            Number(time.minutes) > 0 ? time.minutes + "m" : ""
          }`,
          remaining: `${remaining.hours}h ${
            Number(remaining.minutes) > 0 ? remaining.minutes + "m" : ""
          }`,
        };
      });

      return {
        ...suppliers,
        collection: mapped,
      };
    }
    return {
      collection: [],
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pages: [],
    };
  }, [suppliers]);

  const handlePageClick = (data: any) => {
    let selected = data.selected;
    setPageNumber(selected);
  };
  const handleSearch = (e: any) => {
    setTerm(e.target.value);
  };

  const headers = [
    {
      header: "Full Name",
      identifier: "fullName",
      order: 0,
    },
    {
      header: "Email",
      identifier: "email",
      order: 1,
    },
    {
      header: "Mobile",
      identifier: "mobile",
      order: 2,
    },
    {
      header: "Profession",
      identifier: "profession",
      order: 3,
    },
    {
      header: "Hours available",
      identifier: "timeAvailable",
      order: 4,
    },
    {
      header: "Hours Remaining",
      identifier: "remaining",
      order: 5,
    },
  ];

  return (
    <div className="donors">
      <SearchBar placeholder={"Search..."} onChange={handleSearch} />
      <div className="flex-row justify-content-space-between align-items-center flex-wrap">
        <h1 className="mb-0">Service providers</h1>
        <a
          className="flex-row align-items-center header-link"
          href={adminRoute.CreateServiceProviders()}
        >
          <AddSvg />
          Add provider
        </a>
      </div>
      {!isLoading && suppliers?.collection ? (
        <Table
          headers={headers}
          dataset={mappedSuppliers.collection}
          redirectFn={(data) =>
            navigate(adminRoute.EditServiceProviders(data.supplierId))
          }
        />
      ) : (
        <Loader />
      )}
      <div className="pagination-container">
        {suppliers && pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=""
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageCount}
            pageCount={pageCount}
            previousLabel=""
          />
        ) : null}
      </div>
    </div>
  );
};

export default Suppliers;
