import { apiSlice } from "../../app/api/apiSlice";
import { OtpInterface } from "../../interface/login";

interface OtpData {
  timer: number;
  resetLink: boolean;
  key: string;
  token: string;
  success: boolean;
  loading: boolean;
}

interface OTPExpiry {
  message: string;
  success: boolean;
}

const defaultState: OtpData = {
  timer: 86400000,
  resetLink: false,
  key: "",
  token: "",
  success: true,
  loading: true,
};

export const otpApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    otpVerify: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `users/verify`,
        method: "post",
        body: payload,
      }),
    }),
    otpExpiry: builder.query({
      query: (payload: any) => ({
        url: `users/otp-expire?key=${payload.key}&token=${payload.token}`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    resendOtp: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: "users/resend-otp",
        method: "post",
        body: payload,
      }),
    }),
  }),
});

export const {
  useOtpVerifyMutation,
  useLazyOtpExpiryQuery,
  useResendOtpMutation,
} = otpApiSlice;
