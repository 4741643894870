import Loader from "../../components/loader/loader";
import { useTemplatesQuery } from "../../features/emailTemplates/emailTemplateApiSlice";
import { adminRoute } from "../../routes";

const Emails = () => {
  const { data: templates, isLoading } = useTemplatesQuery({});

  if (isLoading) {
    return (
      <div>
        <h1>Templates</h1>
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <h1 className="mb-2">Email Templates</h1>
      <div className="flex flex-wrap" style={{ gap: "50px" }}>
        {templates
          ?.filter((c: any) => c.notificationMethod === "Email")
          ?.map((c: any) => {
            return (
              <a
                key={c.notificationTemplateId}
                className="document-link"
                href={adminRoute.EmailEdit(c.notificationTemplateId.toString())}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  textDecoration: "none",
                  color: "unset",
                }}
              >
                <img src="/doc.png" alt="doc" width={100} />
                <h3 className="mt-0">
                  {c.notificationType.replace(/([A-Z])/g, " $1").trim()}
                </h3>
              </a>
            );
          })}
      </div>
      <h1 className="mb-2 mt-2">SMS Templates</h1>
      <div className="flex flex-wrap" style={{ gap: "50px" }}>
        {templates
          ?.filter((c: any) => c.notificationMethod === "SMS")
          ?.map((c: any) => {
            return (
              <a
                key={c.notificationTemplateId}
                className="document-link"
                href={adminRoute.EmailEdit(c.notificationTemplateId.toString())}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  textDecoration: "none",
                  color: "unset",
                }}
              >
                <img src="/doc.png" alt="doc" width={100} />
                <h3 className="mt-0">
                  {c.notificationType.replace(/([A-Z])/g, " $1").trim()}
                </h3>
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default Emails;
