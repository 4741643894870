export type role = "Admin" | "Artist" | "SuperAdmin";

export enum NotificationMethod {
  SMS,
  Email,
  BOTH,
}
export interface ArtistType {
  artistTypeId: number;
  description: string | null;
}
export interface InstrumentType {
  instrumentTypeId: number;
  description: string | null | undefined;
}

export enum BenefitsEnum {
  SAVINGS_FUND = "Savings",
  LEGAL_ADVICE = "Legal Cover",
  MEDICAL_AID = "Medical Aid",
  PENSION_FUND = "Pension Fund",
  LIFE_COVER = "Life Cover",
  FUNERAL_COVER = "Funeral Cover",
}

export interface Benefits {
  artistBenefitId: number;
  benefit: BenefitsEnum;
  companyName: string;
  companyContact: string;
  policyNumber: string;
  amount: number;
  index: number;
}

export interface AccountDetailsInterface {
  id: number;
  systemUserId: number;
  createdOn: string;
  modifiedOn: string;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  idNumber: string;
  tikTokHandle: string | null;
  twitterHandle: string | null;
  facebookHandle: string | null;
  instagramHandle: string | null;
  profileImageUrl: string | null;
  artistTypes: ArtistType[] | null;
  instrumentTypes: InstrumentType[] | null;
  benefits: Benefits[];
  pensionFund: number;
  medicalAid: number;
  savings: number;
  lifeCover: number;
  legalCover: number;
  funeralCover: number;
  approved?: Boolean;
  suspended?: Boolean;
  suspendReason?: string;
  rejectReason: string;
  notificationMethod: string;
}

export interface AccountDetailRequestInterface {
  systemUserId?: number;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  idNumber: string;
  tikTokHandle: string | null;
  twitterHandle: string | null;
  facebookHandle: string | null;
  instagramHandle: string | null;
  profileImageUrl: string | null;
  artistTypes: ArtistType[] | null;
  benefits: Benefits[];
  instrumentTypes: InstrumentType[] | null;
  pensionFund: number;
  medicalAid: number;
  savings: number;
  lifeCover: number;
  legalCover: number;
  funeralCover: number;
  approved?: Boolean;
  suspended?: Boolean;
  suspendReason?: string;
  notificationMethod: string;
}
