import React from "react";
import "./search.scss";

const SearchBar = ({ placeholder, onChange }: any) => {
  return (
    <div className="search-container">
      <div className="search-input-wrapper">
        <input
          placeholder={placeholder}
          type="search"
          id="search-input"
          onChange={onChange}
          className="shadow"
          autoComplete="false"
        />
        <div className="search-svg" id="svg-container">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9375 19.938L25.9168 25.9173"
              stroke="#4c4c4c"
              strokeWidth="2.56255"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 12.2502C2 17.9112 6.58918 22.5004 12.2502 22.5004C15.0856 22.5004 17.6523 21.3492 19.5079 19.4886C21.3572 17.6345 22.5004 15.0759 22.5004 12.2502C22.5004 6.58918 17.9112 2 12.2502 2C6.58918 2 2 6.58918 2 12.2502Z"
              stroke="#4c4c4c"
              strokeWidth="2.56255"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
