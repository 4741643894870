import { useEffect, useMemo, useRef, useState } from "react";
import "./searchableSelect.scss";
import { toast } from "react-toastify";

interface option {
  value: any;
  thumbnail: string;
  label: string;
}

interface Selectorprops {
  label?: string;
  invalid?: boolean;
  selectedValue: option;
  options: option[];
  onSelectionChanged: (options: option) => void;
}

const SearchableSelect = (selector: Selectorprops) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [optionLs, setOptionLs] = useState<option[]>(selector.options ?? []);
  const [value, setValue] = useState<option>(selector.selectedValue ?? []);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setValue(selector.selectedValue);
    setOptionLs(selector.options);
  }, [selector.selectedValue]);

  const selectOption = (e: option) => {
    setValue(e);
    setShowOptions(false);
    selector.onSelectionChanged(e);
  };

  const options = useMemo(() => {
    const searchResults = selector.options.filter((c) =>
      c.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    if (searchTerm.length > 0 && searchResults.length === 0) {
      return <li key={searchTerm + "_o"}>No results</li>;
    } else {
      return searchResults.map((c) => {
        const selected = value?.label === c.label || value?.value === c.value;

        return (
          <li
            key={c.value + "_o"}
            className={selected ? "selector--selected" : ""}
            onClick={() => {
              selectOption(c);
            }}
            value={c.value}
          >
            {c.thumbnail && (
              <img src={c.thumbnail} alt="thumbnail" className="thumbnail" />
            )}
            {c.label}
          </li>
        );
      });
    }
  }, [searchTerm, optionLs]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    }

    if (showOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions]);

  return (
    <div className="searchable-selector" ref={dropdownRef}>
      {!!selector.label && <p className="label m-0">{selector.label}</p>}
      <div
        className={`selector--search--wrapper ${
          searchTerm.length > 0 || showOptions ? "selector--expanded" : ""
        }`}
      >
        <input
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholder="Search..."
          className={`selector--search--input ${
            selector.invalid ? "is-invalid" : ""
          }`}
        />
        {!value && (
          <div className="invalid-feedback">Please select a option</div>
        )}
        {searchTerm.length > 0 && (
          <div
            className="selector--clear--search"
            onClick={() => setSearchTerm("")}
          ></div>
        )}
        <div
          className="selector--show--hide"
          onClick={() => setShowOptions(!showOptions)}
        ></div>
      </div>
      <div className="selector--value--wrapper">
        {!!value && (
          <span key={`${value.label}_${value.value}_v`}>{value.label}</span>
        )}
      </div>
      <div className="selector--options--wrapper">
        {(searchTerm.length > 0 || showOptions) && (
          <ul className="selector--options">{options}</ul>
        )}
      </div>
    </div>
  );
};

export default SearchableSelect;
