import { ChangeEvent, useState } from "react";
import { Benefits } from "../../interface/account";
import { Validators } from "../../validators";
import TextInput from "../textInput/textInput";
import "./benefits.scss";

export interface BenefitsChange {
  subKey: keyof Benefits;
  value: string;
}

interface BenefitsProps {
  onFieldChange: (data: BenefitsChange) => void;
  sectionTitle: string;
  values: Benefits;
}

const BenefitsDetails = ({
  onFieldChange,
  sectionTitle,
  values,
}: BenefitsProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const key = name as keyof Benefits;
    onFieldChange({ subKey: key, value });
  };

  return (
    <div>
      <p className="title mt-1">{sectionTitle}</p>
      <div className="benefits-grid">
        <div>
          <TextInput
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={values.companyName}
            inputChanged={handleChange}
          />
        </div>

        <div>
          <TextInput
            type="contact"
            name="companyContact"
            placeholder="Contact Number"
            value={values.companyContact}
            inputChanged={handleChange}
          />
        </div>

        <div>
          <TextInput
            name="policyNumber"
            type="text"
            placeholder="Policy Number"
            value={values.policyNumber}
            inputChanged={handleChange}
          />
        </div>

        <div>
          <TextInput
            type="number"
            name="amount"
            placeholder="Amount"
            value={values.amount}
            inputChanged={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BenefitsDetails;
