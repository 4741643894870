import { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import Table from "../../components/dynamicTable/table";
import SearchBar from "../../components/search/search";
import { AddSvg } from "../../components/svg/add";
import { useGetAdminsQuery } from "../../features/users/usersApiSlice";
import { PageSearchResponse } from "../../interface/queries";
import { adminRoute } from "../../routes";
import Loader from "../../components/loader/loader";
const Administrators = () => {
  const [pageCount, setPageCount] = useState<any>(0);
  const [term, setTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();

  const { data: admins, isLoading } = useGetAdminsQuery({
    pageSize,
    pageNumber,
    term,
  });

  const adminAccounts: PageSearchResponse = useMemo(() => {
    if (admins) {
      setPageCount(admins.totalNumberOfPages);
      const mappedCollection = admins.collection.map((admin: any) => {
        return {
          ...admin,
          fullName: `${admin.name} ${admin.surname}`,
          profileImageUrl: (
            <img
              key={admin.systemUserId}
              alt="profileImage"
              src={
                !!admin.profileImageUrl ? admin.profileImageUrl : "/user.png"
              }
            />
          ),
        };
      });

      return { ...admins, collection: mappedCollection };
    }
    return {
      collection: [],
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pages: [],
    };
  }, [admins]);

  const handlePageClick = (data: any) => {
    let selected = data.selected;
    setPageNumber(selected);
  };
  const handleSearch = (e: any) => {
    setTerm(e.target.value);
  };

  const headers = [
    {
      header: "",
      identifier: "profileImageUrl",
      order: 0,
    },
    {
      header: "Full Name",
      identifier: "fullName",
      order: 1,
    },
    {
      header: "Email",
      identifier: "email",
      order: 2,
    },
    {
      header: "Mobile",
      identifier: "mobile",
      order: 3,
    },
    {
      header: "ID Number",
      identifier: "idNumber",
      order: 4,
    },
    {
      header: "Status",
      identifier: "status",
      order: 5,
    },
  ];

  return (
    <div className="administrators">
      <SearchBar placeholder={"Search..."} onChange={handleSearch} />
      <div className="flex-row justify-content-space-between align-items-center flex-wrap">
        <h1 className="mb-0">Administrators</h1>
        <a
          className="flex-row align-items-center header-link"
          href={adminRoute.CreateAdministrator()}
        >
          <AddSvg />
          Add administrator
        </a>
      </div>
      {!isLoading ? (
        <Table
          headers={headers}
          dataset={adminAccounts.collection}
          redirectFn={(data) =>
            navigate(adminRoute.EditAdministrator(data.systemUserId))
          }
        />
      ) : (
        <Loader />
      )}
      <div className="pagination-container">
        {adminAccounts && pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=""
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageCount}
            pageCount={pageCount}
            previousLabel=""
          />
        ) : null}
      </div>
    </div>
  );
};

export default Administrators;
