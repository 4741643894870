import moment from "moment";
import React, { FormEvent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import TextInput from "../../components/textInput/textInput";
import { selectCurrentUser } from "../../features/auth/authSlice";
import {
  useCommentMutation,
  useGetInvestorQuery,
  useResolveCommentMutation,
  useRevokeDebitOrderMutation,
  useUpdateArtistInvestorMutation,
  useUpdateInvestorStatusMutation,
} from "../../features/investors/investorsApiSlice";
import { useGenerateMandateMutation } from "../../features/payments/paymentsApiSlice";
import { Comment, Investor, InvestorStatus, InvestorStatusEnum } from "../../interface/investor";
import { adminRoute, artistRoute } from "../../routes";
import { Validators } from "../../validators";
import ContactMethods from "../../components/contactMethods/contactMethods";
import StatusModal from "../../components/statusModal/statusModal";
import ConfirmationModal from "../../components/confirmationModal/confirmationModal";
const EditArtistDonor = () => {
  const { investorId, artistId } = useParams();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");

  const [investorStatus, setInvestorStatus] = useState<InvestorStatus>({
    systemUserId: Number(artistId),
    systemUserInvestorId: Number(investorId),
    status: Object.values(InvestorStatusEnum)[0],
    reason: "",
  });

  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [investor, setInvestor] = useState<Investor>({
    name: "",
    surname: "",
    email: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    address5: "",
    occupation: "",
    idNumber: undefined,
  } as Investor);
  const {
    roles,
    givenName: userGivenName,
    email: userEmail,
  } = useSelector(selectCurrentUser);

  const { data: userInvestor, isLoading } = useGetInvestorQuery({
    investorId: Number(investorId ?? 0),
    artistId: Number(artistId),
  });

  const [updateInvestorStatus, { isLoading: submittingStatus }] =
    useUpdateInvestorStatusMutation();
  const [updateInvestor, { isLoading: submitting }] =
    useUpdateArtistInvestorMutation();
  const [createComment, { isLoading: submittingComment }] =
    useCommentMutation();
  const [resolveComment] = useResolveCommentMutation();
  const [generateMandate, { isLoading: generatingMandate }] =
    useGenerateMandateMutation();

  const [revokeDebitOrder, { isLoading: cancellingDebit }] =
    useRevokeDebitOrderMutation();

  const [validations, setValidations] = useState({
    name: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    surname: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    mobile: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isMobile,
        isValid: true,
      },
    ],
    idNumber: [
      {
        check: Validators.isID,
        isValid: true,
      },
    ],
    email: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
      {
        check: Validators.isEmail,
        isValid: true,
      },
    ],
    occupation: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  });

  const AdminValidators = {
    ...validations,
    address1: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],

    address3: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address4: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
    address5: [
      {
        check: Validators.isRequired,
        isValid: true,
      },
    ],
  };

  useMemo(() => {
    if (roles.includes("SuperAdmin") || roles.includes("Admin"))
      setValidations(AdminValidators);
  }, [roles]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInvestor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const valid = Validators.validateState(investor, validations);
    setValidations({ ...validations });

    if (valid) {
      updateInvestor(investor)
        .then(() => {
          toast.success("Donor updated", {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        })
        .catch(() => {
          toast.error("Failed to update donor. Please try again later.", {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          });
        });
    } else {
      const firstError = document.querySelectorAll(".is-invalid");
      if (firstError?.length > 0) {
        window.scrollTo({
          behavior: "smooth",
          top:
            firstError[0].getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            120,
        });
      }

      toast.error("Please ensure all your fields are correct", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }
  };
  useMemo(() => {
    if (userInvestor) {
      setInvestor(userInvestor);
    }
  }, [userInvestor]);

  useMemo(() => {
    if (
      !!investor.idNumber &&
      investor.idNumber > 0 &&
      validations["idNumber"]?.length === 0
    )
      setValidations({
        ...validations,
        idNumber: [
          //@ts-ignore
          {
            check: Validators.isID,
            isValid: true,
          },
        ],
      });
    if (!!!investor.idNumber)
      setValidations({
        ...validations,
        idNumber: [],
      });
  }, [investor]);

  const handleGenerateMandate = () => {
    if (!investor?.debitOrderDay) {
      toast.error("Please select a billing date before generating e-Mandate.", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
      return;
    }
    if (!Validators.isID(investor?.idNumber)) {
      toast.error("Please insert a valid ID number.", {
        position: "top-right",
        autoClose: 5000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
      return;
    }

    updateInvestor(investor)
      .then(() => {
        generateMandate({
          investorId: investorId,
          artistId: artistId,
        })
          .unwrap()
          .then(() => {
            toast.success("e-Mandate sent", {
              position: "top-right",
              autoClose: 5000,
              theme: "colored",
              pauseOnFocusLoss: false,
              pauseOnHover: false,
            });
          })
          .catch(() => {
            toast.error("Failed to generate e-Mandate", {
              position: "top-right",
              autoClose: 5000,
              theme: "colored",
              pauseOnFocusLoss: false,
              pauseOnHover: false,
            });
          });
      })
      .catch(() => {
        toast.error("Failed to generate e-Mandate", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  const handleCancelDebitOrder = () => {
    revokeDebitOrder({
      investorId: investorId,
      artistId: artistId,
    })
      .unwrap()
      .then(() => {
        toast.success("Debit Orders have been cancelled.", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
        setInvestor({ ...investor, isActive: false });
        setConfirmationModalOpen(false);
      })
      .catch(() => {
        toast.error("Failed to cancel Debit Orders", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  const handleCommentAdd = () => {
    createComment({
      artistId,
      investorId,
      comment: {
        comment,
        createdBy: userGivenName,
        createdByEmail: userEmail,
      },
    });
    setComment("");
  };

  const generateButton = useMemo(() => {
    if (!userInvestor || roles.includes("Artist")) return null;

    if (userInvestor.isActive) {
      return (
        <button
          className="error mb-1 "
          onClick={() => setConfirmationModalOpen(true)}
        >
          {!cancellingDebit ? "Cancel Debit Order" : <Loader />}
        </button>
      );
    }
    return (
      <button className="primary mb-1 " onClick={handleGenerateMandate}>
        {!generatingMandate ? "Generate e-Mandate" : <Loader />}
      </button>
    );
  }, [investor, roles, generatingMandate, cancellingDebit, userInvestor]);

  const handleContactChange = (key: string, value: string) => {
    setInvestor((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      {confirmationModalOpen && (
        <ConfirmationModal
          setOpenModal={() => setConfirmationModalOpen(false)}
          title="Are you sure you would like to cancel this debit order?"
          onSubmit={() => handleCancelDebitOrder()}
        />
      )}

      {statusModalOpen && (
        <StatusModal
          setOpenModal={() => setStatusModalOpen(false)}
          status={investorStatus}
          onSubmit={(value) =>{
            debugger
            updateInvestorStatus(value)
              .unwrap()
              .then(() => setStatusModalOpen(false))
              .catch(() => {
                toast.error("Failed to update status.", {
                  position: "top-right",
                  autoClose: 5000,
                  theme: "colored",
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                });
              })
          }}
        />
      )}
      <div className="p-0 edit-investor-header">
        <h1 className="m-0">Edit Angel Investor</h1>
        {(!isLoading || userInvestor) && (
          <div>
            {generateButton}
            {!roles.includes("Artist") && (
              <button
                className="ml-1 primary mb-1"
                onClick={() => setStatusModalOpen(true)}
              >
                Update Status
              </button>
            )}
          </div>
        )}
      </div>
      {isLoading || !userInvestor ? (
        <Loader />
      ) : (
        <>
          <div>
            <ContactMethods
              value={investor.notificationMethod}
              onChange={(value) =>
                handleContactChange("notificationMethod", value)
              }
            />
            <div className="flex-row justify-content-space-between p-0 edit-artist">
              <p className="title">Personal Information</p>
              <div className={`status-label status-${investor.status}`}>
                <p>Status: {investor.status} &#9432;</p>
                {!!investor.reason && (
                  <span className="tooltiptext">{investor.reason}</span>
                )}
              </div>
            </div>
            <div className="input-grid">
              <TextInput
                name="name"
                type="text"
                value={investor.name}
                placeholder="Name"
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                name="surname"
                type="text"
                value={investor.surname}
                placeholder="Surname"
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                name="idNumber"
                type="number"
                value={investor.idNumber}
                placeholder="ID number"
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="string"
                name="mobile"
                placeholder="Contact number"
                value={investor.mobile}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                type="email"
                name="email"
                placeholder="Email address"
                value={investor.email}
                inputChanged={handleChange}
                validations={validations}
              />
              <TextInput
                name="occupation"
                type="text"
                value={investor.occupation}
                placeholder="Occupation"
                inputChanged={handleChange}
                validations={validations}
              />
            </div>
            {(roles.includes("Admin") || roles.includes("Admin")) && (
              <>
                <p className="title">Billing Information</p>
                <div className="input-grid">
                  <TextInput
                    type="text"
                    name="address1"
                    placeholder="Street address"
                    value={investor.address1}
                    inputChanged={handleChange}
                    validations={validations}
                  />
                  <TextInput
                    type="text"
                    name="address2"
                    placeholder="Unit / Building no."
                    value={investor.address2}
                    inputChanged={handleChange}
                    validations={validations}
                  />
                  <TextInput
                    type="text"
                    name="address3"
                    placeholder="Suburb"
                    value={investor.address3}
                    inputChanged={handleChange}
                    validations={validations}
                  />
                  <TextInput
                    type="text"
                    name="address4"
                    placeholder="Province"
                    value={investor.address4}
                    inputChanged={handleChange}
                    validations={validations}
                  />
                  <TextInput
                    type="number"
                    name="address5"
                    placeholder="Postal Code"
                    value={investor.address5}
                    inputChanged={handleChange}
                    validations={validations}
                  />
                </div>

                <div className="input-grid">
                  <div>
                    <p className="label m-0">Billing date</p>
                    <select
                      name="debitOrderDay"
                      id=""
                      defaultValue={investor.debitOrderDay}
                      onChange={(e: any) => handleChange(e)}
                    >
                      <option value="">{"Select a option"}</option>
                      <option value="01">1st</option>
                      <option value="15">15th</option>
                      <option value="25">25th</option>
                      <option value="30">30th</option>
                      <option value="LDOM">Last day of the month</option>
                    </select>
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-row pl-0 mt-2">
              <button
                className="gray"
                onClick={(e) => {
                  e.preventDefault();
                  !!artistId && roles.includes("Artist")
                    ? navigate(artistRoute.profile(artistId))
                    : !!artistId && !roles.includes("Artist")
                    ? navigate(adminRoute.Artist(artistId))
                    : window.location.replace(
                        "http://www.artscapital.co.za/thank-you"
                      );
                }}
              >
                Back
              </button>
              <button type="submit" onClick={onSubmit}>
                {!submitting ? "Save" : <Loader />}
              </button>
            </div>
          </div>
          {!roles.includes("Artist") && (
            <>
              <h2 className="mt-1">Comments</h2>
              {investor.comments ? (
                <div className="comment-list-wrapper">
                  {investor.comments.map((c: Comment) => (
                    <div
                      className="flex-column w-100 p-0 mb-1"
                      key={c.investorCommentId}
                    >
                      <div className="comment-wrapper">
                        <span className="name">{c.createdBy}</span>
                        <span className="date">
                          {moment(c.createdOn).format("Do MMM YYYY, h:mm:ss a")}
                        </span>
                        {c.comment}
                        {!c.resovled && (
                          <button
                            className="resolve"
                            onClick={() => resolveComment(c.investorCommentId)}
                          >
                            Resolve
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No comments logged</p>
              )}

              <div className="input-grid">
                <div>
                  <p className="label">Add a comment</p>
                  <textarea
                    className="brand-input"
                    placeholder="Comments.."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <button className="primary mt-1" onClick={handleCommentAdd}>
                    {!submittingComment ? "Add comment" : <Loader />}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditArtistDonor;
